import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { VideoCard, ScheduleCard } from "../Cards";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import axios from "axios";
import { SeeAllFullScreen } from "../Dialogue";
import { useHistory } from "react-router-dom";

// import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const arr = [
  "appointment1.jpg",
  "appointment2.jpg",
  "appointment3.jpg",
  "appointment2.jpg",
  "appointment3.jpg",
];

export default function ScheduleCarousel(props) {
  const [videos, setVideos] = useState();
  let history = useHistory();
  // const

  // useEffect(() => {
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   const data = {
  //     playlistId: props.playelistInfo.id,
  //     from: 0,
  //     size: 10,
  //   };

  //   axios
  //     .post(URL_GET_PLAYLIST_VIDEOS, data, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       setVideos(response.data);
  //       // console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [props.playelistInfo]);

  // const SeeAll = (
  //   <SeeAllFullScreen
  //     linkText="See All"
  //     // idFromFetch={props.playelistInfo.id}
  //     // urlLink={URL_GET_PLAYLIST_VIDEOS}
  //     primaryText={"See All"}
  //     secondaryText="Just Testing see all"
  //   ></SeeAllFullScreen>
  // );

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          Schedule
          {/* {props.playelistInfo.title} */}
        </Typography>
        <Typography
          variant="h5"
          style={{
            // fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Link onClick={() => history.push("./Schedule")}>See All</Link>
          {/* <SeeAllFullScreen id={props.playelistInfo.id}></SeeAllFullScreen> */}
          {/* {SeeAll} */}
        </Typography>
      </div>
      {/* <Typography variant="caption">Trending in last few hours</Typography> */}

      <div
        style={{
          overflowX: "auto",
          marginBottom: 16,
          overflowY: "hidden",
          // display: "inline-flex",
        }}
      >
        {/* <Grid container spacing={2} wrap="nowrap">
          {videos &&
            videos.map((video) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <VideoCard data={video} titleDown description></VideoCard>
                </Grid>
              );
            })}
        </Grid> */}

        <Grid container spacing={2} wrap="nowrap">
          {arr.map((value, index) => {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                key={index}
              >
                <ScheduleCard scheduleID={value}></ScheduleCard>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </React.Fragment>
  );
}
