// Import FirebaseAuth and firebase.
import React, { useContext } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";
import firebase from "../../../../components/Firebase/firebaseInit";
import AppName from "../../../ReactContext/AppNameContext";

import Avatar from "@material-ui/core/Avatar";

import CssBaseline from "@material-ui/core/CssBaseline";

import Link from "@material-ui/core/Link";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",

  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  // signInSuccessUrl: "/Home",
  signInSuccessUrl: "/",
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // The sign in success callback.
      console.log(authResult);
      // return false;
      return true;
    },
  },
  // Terms of service url.
  tosUrl: "/tos",
  // Privacy policy url.
  privacyPolicyUrl: "/privacyPolicy",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    marginTop: "35vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "black",
    // color: "black",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInScreen() {
  const classes = useStyles();
  const appConfig = useContext(AppName);
  const history = useHistory();
  firebase.auth().tenantId = appConfig.auth_tenant_id;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
        {/* </div> */}
        {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box>*/}
    </Container>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
