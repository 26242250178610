import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import {
  HomeOutlined as Home,
  LocalMallOutlined as Shop,
  ScheduleOutlined as Appointment,
  PermContactCalendarOutlined as Contact,
  MovieFilterOutlined as Exclusive,
  InfoOutlined as About,
} from "@material-ui/icons";

import { white, grey } from "@material-ui/core/colors";
import { InvokeVoiceButton } from "../UtilsComponent/";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import FlashOnIcon from "@material-ui/icons/FlashOn";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
// import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
// import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
// import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    // marginTop: 30,
    top: "auto",
    bottom: 0,
    // background: "black",
    padding: "2",
    borderTop: "1px solid " + grey[200],
    // alignItems: "center",
    // justifyContent: "center",
    // boxShadow: " 2px -8px 20px -1px rgba(0,0,0,0.65)",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  alignCenterText: {
    textAlign: "center",
  },
  textMarginUp: {
    marginTop: -8,
  },
}));

const StyledIconButton = withStyles((theme) => ({
  // root: {
  //   "&:focus": {
  //     backgroundColor: theme.palette.primary.main,
  //   },
  // },
}))(IconButton);

export default function BottomAppBar(props) {
  const classes = useStyles();
  const { selectedMenu } = props;

  let history = useHistory();

  function handlePageOpen(route) {
    history.push(route);
  }

  function onVoiceSearch(text, selectedLangCode) {
    history.push("/search?searchString=" + text);
  }

  const menuArr = ["Home", "Shop", "Exclusive", "About"];

  const renderIcon = (menuName, selectedMenu) => {
    if (menuName == "Home") {
      return selectedMenu == menuName ? <Home color="primary" /> : <Home />;
    } else if (menuName == "Shop") {
      return selectedMenu == menuName ? <Shop color="primary" /> : <Shop />;
    } else if (menuName == "Schedule") {
      return selectedMenu == menuName ? (
        <Appointment color="primary" />
      ) : (
        <Appointment />
      );
    } else if (menuName == "Courses") {
      return selectedMenu == menuName ? (
        <Contact color="primary" />
      ) : (
        <Contact />
      );
    } else if (menuName == "Exclusive") {
      return selectedMenu == menuName ? (
        <Exclusive color="primary" />
      ) : (
        <Exclusive />
      );
    } else if (menuName == "About") {
      return selectedMenu == menuName ? <About color="primary" /> : <About />;
    }
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar style={{ minHeight: 40 }}>
          <Grid container spacing={2}>
            {menuArr.map((menuName, index) => {
              return (
                <Grid
                  item
                  xs={3}
                  className={classes.alignCenterText}
                  key={index}
                >
                  <div style={{ display: "block" }}>
                    <StyledIconButton
                      onClick={() => handlePageOpen("/" + menuName)}
                    >
                      {renderIcon(menuName, selectedMenu)}
                    </StyledIconButton>

                    <Typography
                      variant="caption"
                      className={classes.textMarginUp}
                      component="div"
                    >
                      {menuName}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>

          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
            onClick={() => {
              history.push("./shorts");
            }}
          >
            {/* <InvokeVoiceButton
              onVoiceSearch={onVoiceSearch}
              // initLang={"en-IN"}
              iconStyle={{ color: "white" }}
            ></InvokeVoiceButton> */}
            <FlashOnIcon style={{ width: "32", height: "32" }}></FlashOnIcon>
          </Fab>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
