import React, { useEffect, useContext, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { StoryCard } from "../Cards";
import { grey } from "@material-ui/core/colors";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import axios from "axios";
import { URL_GET_RECENT_FEATURED_CARDS } from "../../../../Static/networkConstant";
import { Skeleton } from "@material-ui/lab";
import InvokeVoiceButton from "../UtilsComponent/invokeVoiceButton";
import { useHistory } from "react-router-dom";
import NotificationButton from "../UtilsComponent/notificationButton";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function StoryTemplate() {
  const appConfig = useContext(AppName);
  const history = useHistory();

  const [featured, setFeatured] = useState();

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      influencer_id: appConfig.influencer_id,
    };

    axios
      .post(URL_GET_RECENT_FEATURED_CARDS, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        setFeatured(response.data.hits);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function onVoiceSearch(text, selectedLangCode) {
    history.push("/search?searchString=" + text);
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 8,
            paddingBottom: 8,
            flexGrow: 1,
          }}
        >
          Featured Now
        </Typography>
        <div style={{}}>
          <InvokeVoiceButton
            onVoiceSearch={onVoiceSearch}
            // initLang={"en-IN"}
            iconStyle={{ color: "black" }}
          ></InvokeVoiceButton>
        </div>
        <NotificationButton />
      </div>
      {/* <Typography
        variant="h4"
        style={{
          fontWeight: 400,
          paddingTop: 8,
          paddingBottom: 16,
          flexGrow: 1,
          color: grey[500],
        }}
      >
        Featured so that you can pin it
      </Typography> */}

      {/* <div
        style={{
          overflowX: "scroll",
        }}
      >
        <Grid container style={{ flexWrap: "nowrap" }} spacing={2}>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
          <Grid item xs={12}>
            <StoryCard></StoryCard>
          </Grid>
        </Grid>
      </div> */}
      {/* // <StoryCard storyID="story2.jpeg"></StoryCard>
          // <StoryCard storyID="story1.jpg"></StoryCard>
          // <StoryCard storyID="story6.jpeg"></StoryCard>
          // <StoryCard storyID="story5.jpeg"></StoryCard>
          // <StoryCard storyID="story3.jpeg"></StoryCard> */}
      <Carousel
        responsive={responsive}
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {featured
          ? featured.map((data, index) => {
              return (
                <StoryCard
                  data={data}
                  featured_action_image_url={data.featured_action_image_url}
                  key={index}
                ></StoryCard>
              );
            })
          : [0, 1, 2].map((value, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                  key={index}
                >
                  <Skeleton variant="rect" height={200} width={280}></Skeleton>
                </Grid>
              );
            })}
      </Carousel>
      {/* <br /> */}
    </React.Fragment>
  );
}
