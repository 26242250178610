import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NotifButton from "../UtilsComponent/notificationButton";
import { iOS } from "../../../../Static/utilsFunc";

export default function IntroPage() {
  return (
    <>
      <ShortsGallery />
    </>
  );
}

const exlArr = [
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/st.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/sc.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/sangeeta.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/dinesh.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/swamiP.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/Marcel.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/beatriz.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji8.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji9.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji1.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji2.jpg",
];

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 30,
  },
  marqueeContent: {
    animation: "$scrolling 20s linear infinite",
  },

  "@keyframes scrolling": {
    "0%": {
      transform: "translateX(0vw)",
    },
    "100%": {
      transform: "translateX(-170vw)",
    },
  },
}));

function ShortsGallery(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div
        style={{
          right: 4,
          top: 4,
          position: "fixed",
          zIndex: 5,
          background: "linear-gradient(transparent, black)",
        }}
      >
        <NotifButton color="white" />
      </div>
      <div
        style={{
          height: "100vh",
          width: "100%",
          background: "black",
          position: "relative",
          overflowY: "hidden",
          // touchAction: "none",
          // paddingBottom: "20vh",
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              background: "linear-gradient(transparent, black)",
              bottom: 0,
              // top: "250px",
              width: "100%",
              height: 80,
              position: "absolute",
              zIndex: 3,
            }}
          ></div>

          <div
            style={{
              overflowX: "auto",
              marginBottom: 16,
              overflowY: "hidden",
              width: "100vw",
              height: "auto",
            }}
          >
            <Grid
              container
              spacing={0}
              wrap="nowrap"
              className={classes.marqueeContent}
            >
              {exlArr.map((value, index) => {
                return (
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    md={1}
                    lg={1}
                    style={{
                      flex: "0 0 auto",
                      webkitOverflowScrolling: "touch",
                    }}
                    key={index}
                  >
                    <img
                      src={value}
                      style={{
                        objectFit: "cover",
                        maxHeight: 250,
                        maxWidth: "100%",
                        height: 250,
                        width: "100%",
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>

        <div
          style={{
            zIndex: 3,
            color: "white",
            position: "absolute",
            top: "280px",
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            // padding: 24,
          }}
        >
          <Typography gutterbottom variant="h2" style={{ fontWeight: 600 }}>
            What do you want to do Today
          </Typography>
        </div>

        <div
          style={{
            zIndex: 3,
            color: "white",
            position: "absolute",
            top: "350px",
            //   alignContent: "center",
            //   textAlign: "center",
            //   justifyContent: "center",
            //   width: "100%",
            // padding: 24,
          }}
        >
          <Container maxWidth={"xl"} style={{ paddingRight: "30px" }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <div
                  style={{
                    border: "1px solid white",
                    width: "100%",
                    height: "100%",
                    padding: 8,
                  }}
                  onClick={() => {
                    history.push("/meditation");
                  }}
                >
                  <Typography
                    gutterbottom
                    variant="h2"
                    style={{ fontWeight: 100, textAlign: "center" }}
                  >
                    Daily Sadhana
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    border: "1px solid white",
                    width: "100%",
                    height: "100%",
                    padding: 8,
                  }}
                  onClick={() => {
                    history.push("/shorts");
                  }}
                >
                  <Typography
                    gutterbottom
                    variant="h2"
                    style={{ fontWeight: 100, textAlign: "center" }}
                  >
                    Short Stories
                  </Typography>
                </div>
              </Grid>

              {!iOS() ? (
                <Grid item xs={6}>
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      height: "100%",
                      padding: 8,
                    }}
                    onClick={() => {
                      history.push(
                        "/search?searchString=Anand%20Rajendran%20ji%20ki%20Japan%20story"
                      );
                    }}
                  >
                    <Typography
                      gutterbottom
                      variant="h2"
                      style={{ fontWeight: 100, textAlign: "center" }}
                    >
                      Voice Search
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      height: "100%",
                      padding: 8,
                    }}
                    onClick={() => {
                      history.push("/home");
                    }}
                  >
                    <Typography
                      gutterbottom
                      variant="h2"
                      style={{ fontWeight: 100, textAlign: "center" }}
                    >
                      Home
                    </Typography>
                  </div>
                </Grid>
              )}
              <Grid item xs={6}>
                <div
                  style={{
                    border: "1px solid white",
                    width: "100%",
                    height: "100%",
                    padding: 8,
                  }}
                  onClick={() => {
                    history.push("/exclusive");
                  }}
                >
                  <Typography
                    gutterbottom
                    variant="h2"
                    style={{ fontWeight: 100, textAlign: "center" }}
                  >
                    Exclusive
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div onClick={() => history.push("/Home")}>
                  <Typography
                    gutterbottom
                    variant="h5"
                    style={{ fontWeight: 400, textAlign: "center" }}
                  >
                    {"Skip>>"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}
