import React, { useState, useEffect } from "react";
import { makeStyles, fade, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RightIcon from "@material-ui/icons/SkipNext";
import LeftIcon from "@material-ui/icons/SkipPrevious";
import { PlayArrow, Pause } from "@material-ui/icons/";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Chip, Grid, Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { convertMS } from "../../../../Static/utilsFunc";

// import { withRouter } from "react-router-dom";
// import { convertMS } from "../../../../static/utilFunc";
import Link from "@material-ui/core/Link";
// import RenderTags from "./searchRenderTags";

// import { maskWords } from "../../../../static/utilFunc";

import {
  green,
  grey,
  red,
  yellow,
  lime,
  blueGrey,
} from "@material-ui/core/colors";

// import {
//   secondsToTimecode,
//   timecodeToSeconds,
// } from "../../../editor/util/timecode-converter";
// const controlsHeight = 400;

const useStyles = makeStyles((theme) => ({
  playerControlContainer: {
    // marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    // height: controlsHeight,
  },

  displayInline: {
    display: "inline",
  },
}));

export function SearchPlayerControl(props) {
  const classes = useStyles();

  const [marks, setMarks] = useState([]);
  const [isSearchCard, setIsSearchCard] = useState(false);
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [dialogueArray, setDialogueArray] = useState([
    { text: props.data.text, startTime: props.data.startTime },
  ]);
  const [currentResult, setCurrentResult] = useState(0);

  //Used for displaying full episode
  const [watchFull, setWatchFull] = useState();
  const [timeToStop, SetTimeToStop] = useState(30);

  const [isPlaying, setIsPlaying] = useState(false);

  // console.log("Tags per Cards", props.tags);

  useEffect(() => {
    const tempMarks = [];
    const dialogueText = [];

    let count = 1;

    // //Best Match set Time
    // setCurrentTime(props.data.startTime / 1000);

    //Setting All the Marks && Setting Total Count && Setting consolidated dialogue array
    //condition added for conditional rendering for dialogues from non dialgoues players
    if (props.data.startTime) {
      setIsSearchCard(true);
      tempMarks.push({ value: props.data.startTime / 1000 });
      console.log({ value: props.data.startTime / 1000 });
      dialogueText.push({
        text: props.data.highlight.dialog[0]
          .replace(/<em>/g, "<b>")
          .replace(/<\/em>/g, "</b>"),
        startTime: props.data.startTime,
      });

      props.data.extraMatches.map((item) => {
        tempMarks.push({ value: item.startTime / 1000 });
        count++;
        dialogueText.push({
          text: item.highlight.dialog[0]
            .replace(/<em>/g, "<b>")
            .replace(/<\/em>/g, "</b>"),
          startTime: item.startTime,
        });
      });

      setMarks(tempMarks);
      setTotalResultCount(count);
      setDialogueArray(dialogueText);
    } else {
      setIsSearchCard(false);
    }
  }, [props.data]);

  useEffect(() => {
    setCurrentTime(dialogueArray[currentResult].startTime / 1000);
    SetTimeToStop(dialogueArray[currentResult].startTime / 1000 + 30);
  }, [currentResult]);

  // useEffect(() => {
  //   // console.log("Inside set Start Time");
  //   // console.log("videoLoaded", videoLoaded);
  //   setCurrentTime(dialogueArray[0].startTime / 1000);
  // }, [props.videoLoaded, dialogueArray]);

  const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

  const IOSSlider = withStyles({
    root: {
      color: "#3880ff",
      height: 2,
      padding: "15px 0",
    },
    thumb: {
      height: 0,
      width: 0,
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      marginTop: -14,
      marginLeft: -14,
      "&:focus,&:hover,&$active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 11px)",
      top: -22,
      "& *": {
        background: "transparent",
        color: "#000",
      },
    },
    track: {
      height: 4,
      backgroundColor: "#bfbfbf",
    },
    rail: {
      height: 4,
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 2,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: "black",
    },
  })(Slider);

  // MEDIA_DURATION_SECS

  function handleProgressBarClickSlider(event, value) {
    console.log(value);

    const time = value;

    setCurrentTime(time);
  }

  function setCurrentTime(newCurrentTime) {
    if (newCurrentTime !== "" && newCurrentTime !== null) {
      if (props.videoRef.current !== null) {
        const videoRef = props.videoRef.current;
        // videoRef.currentTime = newCurrentTime;
        videoRef.seekTo(newCurrentTime);
        if (props.open) {
          playMedia();
        } else {
          pauseMedia();
        }
      } else {
        console.log("no videoREf found");
      }
    }
  }

  function pauseMedia() {
    // setIsPlaying(false);
    props.videoRef.current.pause();
    setIsPlaying(false);
  }

  function playMedia() {
    // setIsPlaying(true);
    props.videoRef.current.play();
    setIsPlaying(true);
  }

  function handleOnClick(e) {
    // e.preventDefault();
    // e.stopPropagation();
  }

  function togglePlayPause() {
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  }

  function modArrayIndex(x, n) {
    return ((x % n) + n) % n;
  }

  function handlePrevButtonClick(e) {
    e.stopPropagation();
    setCurrentResult((oldValue) => {
      //   return (oldValue - 1) % totalResultCount;
      return modArrayIndex(oldValue - 1, totalResultCount);
    });
  }

  function handleNextButtonClick(e) {
    e.stopPropagation();
    console.log(totalResultCount);
    setCurrentResult((oldValue) => {
      return modArrayIndex(oldValue + 1, totalResultCount);
      //   return (oldValue + 1) % totalResultCount;
    });
  }

  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <div
      className={classes.playerControlContainer}
      style={{ height: "calc(" + props.controlHeight + " - 70px)" }}
    >
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          style={{ fontWeight: 600, marginBottom: 8 }}
          noWrap
        >
          {/* {props.data.FILE_NAME} */}

          {/* Who is Shiva */}
          {props.data.title}
        </Typography>
        <IOSSlider
          aria-label="ios slider"
          // defaultValue={60}
          marks={marks}
          valueLabelDisplay="off"
          value={props.currentTime}
          min={0}
          max={props.mediaDuration}
          // max={Number(props.data.MEDIA_DURATION_SECS)}
          onChange={handleProgressBarClickSlider}
          onClick={handleOnClick}
        />
        <div
          style={{ marginBottom: "0px", display: "flex", textAlign: "left" }}
        >
          <Typography
            variant="subtitle2"
            // component="div"
            style={{ flexGrow: 1 }}
          >
            {convertMS(parseInt(props.currentTime) * 1000)}
          </Typography>
          <Typography
            variant="subtitle2"
            // component="div"
          >
            {/* {convertMS(Number(props.data.MEDIA_DURATION_SECS) * 1000)} */}
            {convertMS(Number(props.mediaDuration) * 1000)}
          </Typography>
        </div>

        <div style={{ marginTop: "-10px" }}>
          <IconButton onClick={handlePrevButtonClick} disabled={!isSearchCard}>
            <LeftIcon />
          </IconButton>
          <IconButton onClick={togglePlayPause}>
            {isPlaying ? (
              <Pause
                fontSize="inherit"
                style={{ height: "40px", width: "40px" }}
              />
            ) : (
              <PlayArrow
                fontSize="iherit"
                style={{ height: "40px", width: "40px" }}
              />
            )}
          </IconButton>
          <IconButton onClick={handleNextButtonClick} disabled={!isSearchCard}>
            <RightIcon />
          </IconButton>

          {/* <Chip
          size="small"
          style={{
            marginLeft: 10,
          }}
          label={props.data.MEDIA_DURATION_SECS * 1000}
        /> */}
        </div>
      </Container>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          borderTop: "1px solid " + grey[200],
          padding: 8,
          background: grey[50],
          width: "100%",
          // background: "black",
          // color: "white",
        }}
      >
        <Grid container spacing={1} wrap={"nowrap"}>
          <Grid item>
            <Chip
              size="small"
              label={currentResult + 1 + "/" + totalResultCount}
              // className={classes.chip}
            />
          </Grid>
          <Grid item>
            <Chip
              size="small"
              label={dialogueArray[currentResult].startTime}
              // className={classes.chip}
            />
          </Grid>
          <Grid item>
            {/* <Typography variant="caption">
              {dialogueArray[currentResult].startTime + " — "}
            </Typography> */}
            <div style={{ width: "65vw" }}>
              <Typography variant="subtitle2" noWrap>
                <div
                  className={classes.displayInline}
                  dangerouslySetInnerHTML={createMarkup(
                    dialogueArray[currentResult].text
                  )}
                />
              </Typography>
            </div>
          </Grid>
          {/* <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="caption"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dialogueArray[currentResult].startTime + " — "}

                        <div
                          className={classes.displayInline}
                          dangerouslySetInnerHTML={createMarkup(
                            dialogueArray[currentResult].text
                          )}
                        />
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List> */}
        </Grid>
      </div>
    </div>
  );
}

export default SearchPlayerControl;
