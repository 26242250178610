//databaseName
export const COLLECTION_FEATURED = "db-app-generator-admin-featured";
export const COLLECTION_NOTF = "db-app-generator-admin-saved-notf";
export const COLLECTION_USER = "db-app-generator-admin-influencer-config";

//Merchandise
export const SUB_COLLECTION_MERCHANDISE_CATEGORY = "merchandise-category";
export const SUB_COLLECTION_MERCHANDISE_SUB_CATEGORY =
  "merchandise-subcategory";
export const SUB_COLLECTION_MERCHANDISE_PRODUCT = "merchandise-product";

export const SUB_COLLECTION_PINNED_SEARCH = "pinned-search";
export const SUB_COLLECTION_YOUTUBE_PLAYLIST = "youtube-playList";

//Exclusive Playlist
export const SUB_COLLECTION_EXCLUSIVE_PLAYLIST = "exclusive-playlist";
export const SUB_COLLECTION_EXCLUSIVE_SUB_PLAYLIST = "exclusive-subplaylist";
export const SUB_COLLECTION_EXCLUSIVE_VIDEOS = "exclusive-videos";

//App First
export const SUB_COLLECTION_APP_FIRST_VIDEO = "app-first-video";
export const SUB_COLLECTION_RECENT_VIDEOS = "recent-videos";

//Notification
export const SUB_COLLECTION_NOTIFICATION = "notification";

//Donation
export const SUB_COLLECTION_DONATION = "db-donation";