import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import {
  COLLECTION_USER,
  SUB_COLLECTION_EXCLUSIVE_PLAYLIST,
  SUB_COLLECTION_EXCLUSIVE_SUB_PLAYLIST,
} from "../../../../Static/appContstants";
import { pink } from "@material-ui/core/colors";
import axios from "axios";
import { SeeAllFullScreen } from "../Dialogue/";
import firebase from "../../../Firebase/firebaseInit";
import { StarBorderOutlined } from "@material-ui/icons";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import { useHistory } from "react-router-dom";

const arr = [
  "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/to-delete/1__1H0AJZghe7PMAqMxyaWyw.png",
  // "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/to-delete/15bgmswitchjpg.jpeg",
  "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/to-delete/20180620050508-srinivas-image.jpeg",
  "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/to-delete/image.png",
];

export default function PopularTeacher(props) {
  const [playlists, setPlaylists] = useState();
  const history = useHistory();
  //   const [playlistData, setPlaylistData] = useState();

  const { playlist_id, playlist_name } = props.data;
  const { titleDown, premium } = props;
  console.log(playlist_id, playlist_name);
  const appConfig = useContext(AppName);

  // const

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_PLAYLIST)
      .doc(playlist_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_SUB_PLAYLIST)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        // console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data();
          data["subplaylist_id"] = doc.id;

          //   arrayToPush.push(doc.data());
          arrayToPush.push(data);
          console.log(doc.id, " => ", doc.data());
        });
        // console.log(arrayToPush);
        setPlaylists(arrayToPush);
      });
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          {playlist_name}
        </Typography>
      </div>

      <div
        style={{
          overflowX: "auto",
          marginBottom: 16,
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={1} wrap="nowrap">
          {playlists?.map((playlist, index) => {
            return (
              <Grid
                item
                xs={4}
                sm={2}
                md={1}
                lg={1}
                style={{
                  flex: "0 0 auto",
                  webkitOverflowScrolling: "touch",
                }}
                key={index}
              >
                <center>
                  <div style={{ position: "relative" }}>
                    {premium && (
                      <StarBorderOutlined
                        size="small"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          margin: 4,
                          padding: 2,
                          // background: pink["A400"],
                          background: "black",
                          borderRadius: 4,
                          color: "white",
                          height: 14,
                          width: 14,
                        }}
                      />
                    )}
                    <img
                      onClick={() => {
                        premium
                          ? history.push(
                              "/playlist?id=" +
                                playlist_id +
                                "&primaryText=" +
                                playlist.subplaylist_name +
                                "&dataSource=FS" +
                                "&subplaylist_id=" +
                                playlist.subplaylist_id +
                                "&playlist_image=" +
                                playlist.image_url +
                                "&premium=1"
                            )
                          : history.push(
                              "/playlist?id=" +
                                playlist_id +
                                "&primaryText=" +
                                playlist.subplaylist_name +
                                "&dataSource=FS" +
                                "&subplaylist_id=" +
                                playlist.subplaylist_id +
                                "&playlist_image=" +
                                playlist.image_url
                            );
                      }}
                      src={playlist.image_url}
                      style={{
                        objectFit: "cover",
                        maxHeight: 150,
                        maxWidth: "100%",
                        height: 150,
                        width: "100%",
                        boxShadow: "-1px 3px 8px -5px rgba(122,117,122,0.8)",
                        borderRadius: "8px",
                      }}
                    />
                  </div>

                  {titleDown && (
                    <Typography variant="caption">
                      {playlist.subplaylist_name}
                    </Typography>
                  )}
                </center>
              </Grid>
            );
          })}

          {!playlists &&
            [0, 1, 2].map((value, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                  key={index}
                >
                  <Skeleton variant="rect" height={150} width={100}></Skeleton>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </React.Fragment>
  );
}
