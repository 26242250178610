import React, { useEffect, useState, useContext, useRef } from "react";
import { Typography, IconButton, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { InView } from "react-intersection-observer";
import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import axios from "axios";
import { URL_GET_SHORTS } from "../../../../Static/networkConstant";
import AppName from "../../../ReactContext/AppNameContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import TheatersIcon from "@material-ui/icons/Theaters";
import EmojiIcon from "@material-ui/icons/EmojiEmotions";
import CakeIcon from "@material-ui/icons/Cake";
import ShareIcon from "@material-ui/icons/Share";
import PlayIcon from "@material-ui/icons/PlayArrow";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Fab from "@material-ui/core/Fab";
import { green, yellow } from "@material-ui/core/colors";
import queryString from "query-string";
import { makeStyles } from "@material-ui/styles";
import withAuthnetication from "../../../session/withAuthentication";
import { iOS } from "../../../../Static/utilsFunc";
import Tooltip from "@material-ui/core/Tooltip";
import { PauseOutlined } from "@material-ui/icons";

// const arr = ["457286396", "457293547", "457293721"];
// const youArr = [
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "0" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "1" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "2" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "3" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "5" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "6" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "7" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "8" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "9" },
//   { videoID: "Xgpnksu2-Uo", startTime: "1057", endTime: "1087", index: "10" },
// ];

// const arr = [{ id: "Xgpnksu2-Uo", start: "1057", stop: "" }];
var session_index = 0;
const MAX_VIDEO_LOAD = 10;

const SHOW_CLOSE = false;

// export default withAuthnetication(ShortVideos);

export default function ShortVideos() {
  let location = useLocation();
  const divScrollRef = useRef();
  const {
    youtubeVideoId,
    start_sec,
    end_sec,
    title,
    no_intro,
  } = queryString.parse(location.search);

  const [videos, setVideos] = useState(
    youtubeVideoId
      ? [
          {
            _source: {
              youtubeVideoId: youtubeVideoId,
              start_sec: start_sec,
              end_sec: end_sec,
              title: title,
            },
          },
        ]
      : []
  );
  const [videoPreload, setVideoPreload] = useState(
    youtubeVideoId
      ? [
          {
            _source: {
              youtubeVideoId: youtubeVideoId,
              start_sec: start_sec,
              end_sec: end_sec,
            },
          },
        ]
      : []
  );
  const [loadedCount, setLoadedCount] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxLoaded, setMaxLoaded] = useState(false);
  const appConfig = useContext(AppName);
  const [showClose, setShowClose] = useState(false);
  const history = useHistory();

  useEffect(() => {
    initLocalStorage();
  }, []);

  function initLocalStorage() {
    let seed_value = localStorage.getItem("seed_value");
    if (!seed_value) {
      seed_value = uuidv4();
      localStorage.setItem("seed_value", seed_value);
    }

    localStorage.setItem("current_index", 1);

    let allTimeIndex = localStorage.getItem("all_time_index");
    if (!allTimeIndex) {
      allTimeIndex = 0;
      localStorage.setItem("all_time_index", allTimeIndex);
    }
    session_index = allTimeIndex;

    getVideoFromES(allTimeIndex, seed_value);
  }

  function resetLocalStorage() {
    localStorage.setItem("seed_value", uuidv4());
    localStorage.setItem("current_index", 1);
    localStorage.setItem("all_time_index", 0);
    initLocalStorage();
  }

  function getVideoFromES(from, seed_value) {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      from: parseInt(from),
      size: 12,
      randomSeed: seed_value,
      influencer_id: appConfig.influencer_id,
    };

    axios
      .post(URL_GET_SHORTS, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        console.log(response);
        if (response.data.length <= 3) {
          resetLocalStorage();
        } else {
          //Player Load Data
          if (videos.length == 1) {
            setVideos((oldValue) => [...oldValue, response.data[0]]);
            setLoadedCount(2);
          } else if (videos.length == 0) {
            setVideos((oldValue) => [response.data[0], response.data[1]]);
            setLoadedCount(2);
          }
          //Preload All Data
          setVideoPreload((oldValue) => [...oldValue, ...response.data]);
        }

        // setVideos((oldValue) => [...oldValue, ...response.data]);
        // setVideos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function loadMore() {
    if (videos.length <= MAX_VIDEO_LOAD) {
      // setVideos(youArr.slice(0, loadedCount + 2));
      console.log("loadMore", videoPreload.length, loadedCount);
      if (loadedCount + 5 >= videoPreload.length) {
        console.log("Preload More", videoPreload.length, loadedCount);
        resetLocalStorage();
        setLoadedCount((prev) => prev + 2);
        setVideos(videoPreload.slice(0, loadedCount + 2));
      } else {
        setLoadedCount((prev) => prev + 2);
        setVideos(videoPreload.slice(0, loadedCount + 2));
      }
    } else {
      setMaxLoaded(true);
    }

    // getVideoFromES(loadedCount);
  }

  function inViewCallBackParent(inview, entry, videoRef, videoLoaded) {
    // console.log(entry.target.getAttribute("id"));
    // console.log(entry.target.getAttribute("index"));

    const id = entry.target.getAttribute("id");
    const index = entry.target.getAttribute("index");

    console.log(index, id, "inview", inview, videoLoaded, videoRef);

    //changing index for current session

    if (inview) {
      if (localStorage.getItem("current_index")) {
        if (parseInt(index) + 1 > localStorage.getItem("current_index")) {
          localStorage.setItem("current_index", parseInt(index) + 1);
        }
      } else {
        localStorage.setItem("current_index", parseInt(index) + 1);
      }

      //changing index globally
      // const allTimeIndex = localStorage.getItem("all_time_index");
      const currentIndex = localStorage.getItem("current_index");
      localStorage.setItem(
        "all_time_index",
        parseInt(session_index) + parseInt(currentIndex)
      );
    }

    //pagination
    // if (loadedCount - 1 == index && inview) {
    //   loadMore();
    // }

    if (loadedCount - 1 == index && inview) {
      window.setTimeout(function () {
        loadMore();
      }, 2000);
    }

    if (inview) {
      console.log("play", index);
      // window.setTimeout(function () {

      // videoLoaded && videoRef.current && videoRef.current.setVolume(0.98);
      videoLoaded && videoRef.current && videoRef.current.play();
      // window.setTimeout(function () {
      //   videoLoaded && videoRef.current && videoRef.current.setVolume(1);
      //   videoLoaded && videoRef.current && videoRef.current.play();
      // }, 2000);
    } else {
      console.log("pause", index);
      videoLoaded && videoRef.current.play();
      videoLoaded && videoRef.current.pause();
    }
  }

  function onWatchShowClose() {
    setShowClose(true);
  }

  return (
    <>
      {(showClose || no_intro) && (
        <div
          style={{
            position: "fixed",
            padding: "4px",
            // height: "10vh",
            top: 0,
            right: 0,
            zIndex: 3,
            // width: "100%",
            background: "linear-gradient(black,transparent)",
          }}
        >
          <IconButton
            onClick={() =>
              no_intro ? history.goBack() : history.push("/Home")
            }
          >
            <HighlightOffIcon
              style={{ color: "white", height: 50, width: 50 }}
            />
          </IconButton>
        </div>
      )}
      <div
        ref={divScrollRef}
        style={{
          WebkitOverflowScrolling: "touch" /* important for iOS */,
          scrollSnapType: "y mandatory",
          // overflow: "visible",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        {!no_intro && (
          <ShortsGallery
            divRef={divScrollRef}
            onWatchShowClose={onWatchShowClose}
          />
        )}

        {videos?.map((value, index) => {
          return (
            <YoutubeCards
              key={index}
              data={value}
              index={index}
              title={title}
              inViewCallBackParent={inViewCallBackParent}
              no_intro={no_intro}
            ></YoutubeCards>
          );
        })}
        {maxLoaded && (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              scrollSnapAlign: "start",
              scrollSnapStop: "always",
              background: "black",
              position: "relative",
            }}
          >
            <div
              color="secondary"
              size="large"
              variant="contained"
              style={{
                position: "absolute",
                top: "40%",
                left: "30%",
              }}
              onClick={() => {
                history.push("/temp");
                history.goBack();
              }}
            >
              <Fab
                variant="extended"
                // color="secondary"
                color="default"
                style={{
                  height: "auto",
                  display: "block",
                  background: yellow[700],
                  // color: yellow[700],
                  // color: "white",
                }}
              >
                <EmojiIcon style={{ height: 128, width: 128 }} />
                {/* <CakeIcon style={{ height: 128, width: 128 }} /> */}
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600, paddingBottom: 4 }}
                >
                  Touch to Get Surprised
                </Typography>
              </Fab>
            </div>
          </div>
        )}

        {(showClose || no_intro) && (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              padding: 8,
              zIndex: 2,
              color: "white",
              textAlign: "center",
              width: "100%",
            }}
          >
            <div>
              <KeyboardArrowUpIcon style={{ height: 64, width: 128 }} />
            </div>
            <div>
              <Typography variant="subtitle">Swipe Up</Typography>
            </div>
          </div>
        )}
      </div>
      {/* <BottomAppBar /> */}
    </>
  );
}

var currentInview = {};

function YoutubeCards(props) {
  const [mediaDuration, setMediaDuration] = useState(0);
  const [mediaCurrentTime, setMediaCurrentTime] = useState(0);
  const [watchFull, setWatchFull] = useState(false);
  // const { startTime, endTime } = props.data;
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [oneTimeScroll, setOneTimeScroll] = useState(false);

  const { youtubeVideoId, end_sec, start_sec, title } = props.data._source;

  const [playing, setPlaying] = useState(false);
  const [showPlayBackdrop, setShowPlayBackdrop] = useState(true);

  //   const [currentInview, setCurrentInView] = useState();//state change inView callback doesn't work function

  // let currentInview = "";
  // console.log("OuterView", currentInview, props.data.index);

  const videoRef = React.createRef();

  function share() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "shorts",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text: "Watch : " + title,
          url:
            "https://gurugracestories.aapkaapp.com/shorts?youtubeVideoId=" +
            youtubeVideoId +
            "&start_sec=" +
            start_sec +
            "&end_sec=" +
            end_sec +
            "&title=" +
            title,
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  function onLoadedDataGetDuration(e) {
    const currentDuration = e.target.duration;
    console.log(currentDuration);
    setMediaDuration(currentDuration);
  }

  function onReady() {
    console.log("Ready index", props.index);
    if (props.index == 0) {
    }
    videoRef.current.play();
    // videoRef.current.setVolume(1);
    videoRef.current.pause();
    // videoRef.current.setVolume(1);

    // videoRef.current.seekTo(start_sec);

    setVideoLoaded(true);
  }

  function onPlay() {
    // currentInview[props.data.index]

    console.log(currentInview[props.index], props.index, "OnPlay");
    // console.log(currentInview, props.data.index, "OnPlay");
    if (!currentInview[props.index]) {
      console.log("Paused in onPlay", props.index);
      videoLoaded && videoRef.current.play();
      videoLoaded && videoRef.current.pause();
    } else {
      videoRef.current.setVolume(1);
    }

    // videoRef.current.pause();
  }

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // console.log(parseInt(currentTime).toString());

    //end_sec - 70
    // parseInt(start_sec) + 10
    // parseInt(end_sec)
    console.log(currentTime, end_sec);

    if (currentTime >= parseInt(end_sec)) {
      // console.log(e.target.currentTime);
      // console.log("Paused in handle time update");
      // videoRef.current.setVolume(0);
      videoRef.current.mute();
      videoLoaded && videoRef.current.pause();
      setWatchFull(true);
      // if (!oneTimeScroll) {
      //   const scrollTo = window.scrollY + 500;
      //   setOneTimeScroll(true);
      //   // window.setTimeout
      //   window.setTimeout(function () {
      //     window.scroll({
      //       top: scrollTo,
      //       left: 0,
      //       behavior: "smooth",
      //     });
      //   }, 1000);
      // }
    }
    setMediaCurrentTime(currentTime);
  }

  function startVideo() {}

  function inViewCallBack(inview, entry, videoRef, videoLoaded) {
    currentInview[props.index] = inview;
    // setCurrentInView(inview);
    console.log("currentInView", currentInview);
    props.inViewCallBackParent(inview, entry, videoRef, videoLoaded);
  }

  function openYoutube() {
    // window.location = "https://www.youtube.com/watch?v=" + youtubeVideoId;
    window.open("https://www.youtube.com/watch?v=" + youtubeVideoId);
  }

  const videoPlayer = (
    <UniversalVideoPlayer
      videoRef={videoRef}
      onTimeUpdate={handleTimeUpdate}
      onLoadedDataGetDuration={onLoadedDataGetDuration}
      onReady={onReady}
      onPlay={onPlay}
      // volume={props.index == 0 ? 1 : 0}
      volume={props.no_intro && props.index == 0 ? 1 : 0}
      //   onClick={handlePlayMedia}
      // mediaUrl={
      //   "https://www.youtube-nocookie.com/watch?v=" +
      //   props.data.videoID +
      //   "&?rel=0&modestbranding=1"
      // }
      mediaUrl={
        "https://www.youtube-nocookie.com/watch?v=" +
        youtubeVideoId +
        "&?start=" +
        start_sec +
        "&end=" +
        end_sec +
        2
      }
      // rel=0&modestbranding=1
    ></UniversalVideoPlayer>
  );

  return (
    <InView
      // id={props.data.videoID}
      id={youtubeVideoId}
      index={props.index}
      threshold={0.5}
      as="div"
      onChange={(inview, entry) =>
        inViewCallBack(inview, entry, videoRef, videoLoaded)
      }
      style={{
        // height: "100vh",
        height: "100%",

        width: "100vw",
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        // WebkitOverflowScrolling:"touch"

        background: "black",
        position: "relative",
      }}
    >
      <Typography
        variant="h4"
        style={{
          position: "absolute",
          color: "white",
          bottom: "20%",
          padding: 8,
          zIndex: 3,
        }}
      >
        {/* {props.data.index + "  " + props.data.videoID} */}
        {" " + title ? title : props.title}
      </Typography>
      <Button
        color="default"
        size="large"
        style={{
          color: "white",

          // padding: "4px",
          position: "absolute",
          bottom: "15%",
          // top: "40%",
          // left: "30%",
        }}
        onClick={openYoutube}
        startIcon={<TheatersIcon />}
      >
        Watch Full Video
      </Button>
      <div
        style={{
          position: "absolute",
          bottom: "30%",
          padding: 8,
          right: 0,
          zIndex: 3,
          display: "flex",
          flexFlow: "column wrap",
          touchAction: "manipulation",
        }}
      >
        <Fab
          aria-label="like"
          style={{ background: green[500], color: "white" }}
          onClick={() => share(youtubeVideoId, end_sec, start_sec, title)}
        >
          <WhatsAppIcon size="large" />
        </Fab>

        <br />
        {!iOS() ? (
          <Fab
            aria-label="play"
            color="secondary"
            onClick={() => {
              videoRef.current.setVolume(1);
              videoRef.current.pause();
              videoRef.current.play();
            }}
          >
            <PlayIcon />
          </Fab>
        ) : (
          <Fab
            aria-label="play"
            color="secondary"
            onClick={() => {
              // videoRef.current.setVolume(1);
              // iOS() && videoRef.current.pause();
              if (playing) {
                // videoRef.current.setVolume(1);
                videoRef.current.pause();
                videoRef.current.play();
                // videoRef.current.play();
                // setPlaying(false);
              } else {
                videoRef.current.pause();
                videoRef.current.play();
                // setPlaying(true);
                setShowPlayBackdrop(false);
              }
            }}
          >
            <PlayIcon />
            {/* {playing ? (
              <PauseOutlined />
            ) : (
              <LightTooltip title="Click To Play" open={true} arrow>
                <PauseOutlined />
              </LightTooltip>
            )} */}
          </Fab>
        )}
      </div>
      {watchFull && (
        <React.Fragment>
          <div
            onClick={openYoutube}
            // className={classes.backGradient}
            style={{
              zIndex: 2,
              height: "100vh",
              width: "100%",
              // zIndex: ,
              position: "absolute",
              top: 0,
              background: "black",
              // background:
              //   "linear-gradient(to bottom, rgba(0,0,0,0.65) 13%,rgba(0,0,0,0.33) 56%,rgba(0,0,0,0.05) 94%,rgba(0,0,0,0) 100%)",
            }}
          ></div>

          <Button
            color="default"
            size="large"
            style={{
              zIndex: 2,
              color: "white",
              // padding: "4px",
              position: "absolute",
              top: "40%",
              left: "30%",
            }}
            onClick={openYoutube}
            startIcon={<TheatersIcon />}
          >
            Watch Full Video
          </Button>
        </React.Fragment>
      )}

      {iOS() && showPlayBackdrop && (
        <div
          style={{
            background: "black",
            position: "absolute",
            zIndex: 2,
            width: "100vw",
            height: "100vh",
          }}
        >
          <Typography
            style={{
              color: "white",
              left: "27vw",
              top: "45vh",
              position: "absolute",
            }}
          >
            Double Tap the play button
          </Typography>
        </div>
      )}

      <span style={{ pointerEvents: "none" }}> {videoPlayer}</span>
    </InView>
  );
}

const exlArr = [
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/st.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/sc.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/sangeeta.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/dinesh.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/swamiP.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/Marcel.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/beatriz.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji8.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji9.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji1.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji2.jpg",
];

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 30,
  },
  marqueeContent: {
    animation: "$scrolling 20s linear infinite",
  },

  "@keyframes scrolling": {
    "0%": {
      transform: "translateX(0vw)",
    },
    "100%": {
      transform: "translateX(-170vw)",
    },
  },
}));

function ShortsGallery(props) {
  const classes = useStyles();
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        background: "black",
        position: "relative",
        overflowY: "hidden",
        touchAction: "none",
        // WebkitOverflowScrolling: "touch",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            background: "linear-gradient(transparent, black)",
            bottom: 0,
            width: "100%",
            height: 80,
            position: "absolute",
            zIndex: 2,
          }}
        ></div>

        <Grid
          container
          spacing={0}
          wrap="nowrap"
          className={classes.marqueeContent}
          // style={{ overflowY: "hidden" }}
        >
          {exlArr.map((value, index) => {
            return (
              <Grid
                item
                xs={4}
                sm={2}
                md={1}
                lg={1}
                style={{
                  flex: "0 0 auto",
                }}
                key={index}
              >
                <img
                  src={value}
                  style={{
                    objectFit: "cover",
                    maxHeight: 250,
                    maxWidth: "100%",
                    height: 250,
                    width: "100%",
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div
        style={{
          zIndex: 3,
          color: "white",
          position: "absolute",
          top: "60vh",
          alignContent: "center",
          textAlign: "center",
          width: "100%",
          // padding: 24,
        }}
      >
        <Typography gutterbottom variant="h1" style={{ fontWeight: 600 }}>
          Shorts
        </Typography>
        {/* <br /> */}
        <Typography component="div" style={{ fontWeight: 400 }}>
          A series of never ending short snippet and stories which you will love
        </Typography>
        <Button
          variant="contained"
          size="large"
          style={{ marginTop: "4px", fontWeight: 600, color: "black" }}
          onClick={() => {
            props.onWatchShowClose();
            const innerHeight = window.innerHeight;
            props.divRef.current.scroll({
              top: innerHeight + 50,
              left: 0,
              behavior: "smooth",
            });
            // window.scroll({
            //   top: 500,
            //   left: 0,
            //   behavior: "smooth",
            // });
          }}
        >
          Watch
        </Button>
      </div>
    </div>
  );
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
