import React, { useEffect, useState } from "react";
import { ChannelIdView, CreateAppView, WorkSelectorView } from "./index";

export default function ViewTemplateController(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const { stepCount } = props;

  const createApp = () => (
    <CreateAppView onClickIncrement={onClickIncrement}></CreateAppView>
  );
  const work = () => (
    <WorkSelectorView onClickIncrement={onClickIncrement}></WorkSelectorView>
  );
  const channelId = () => (
    <ChannelIdView onClickIncrement={onClickIncrement}></ChannelIdView>
  );

  const renderStepCount = () => {
    if (currentStep == 1) {
      //   alert("inside");
      return createApp();
    }
    if (currentStep == 2) {
      return work();
    }
    if (currentStep == 3) {
      return channelId();
    }
  };

  function incrementStep() {
    if (currentStep != stepCount) {
      setCurrentStep((oldValue) => oldValue + 1);
    } else {
      console.log("");
    }
  }

  function decrementStep() {
    if (currentStep != 0) {
      setCurrentStep((oldValue) => oldValue - 1);
    } else {
      console.log("");
    }
  }

  function onClickIncrement() {
    incrementStep();
  }

  function onClickDecrement() {
    decrementStep();
  }

  return <React.Fragment>{renderStepCount()}</React.Fragment>;
}
