import React from "react";
import { IconButton, Button } from "@material-ui/core";

import ShareIcon from "@material-ui/icons/Share";

export default function ShareButton(props) {
  function handleClickOpen() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "app",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }
  return (
    <React.Fragment>
      {!props.button ? (
        <IconButton onClick={handleClickOpen}>
          <ShareIcon style={props.iconStyle ? props.iconStyle : {}} />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClickOpen}
          //   className={classes.button}
          startIcon={<ShareIcon />}
        >
          Share App
        </Button>
      )}
    </React.Fragment>
  );
}
