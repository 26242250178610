import React, { useEffect, useContext, useState } from "react";
import AppName from "../../../ReactContext/AppNameContext";
import { Grid, Typography, Container, Chip, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  COLLECTION_USER,
  SUB_COLLECTION_DONATION,
} from "../../../../Static/appContstants";
import firebase from "../../../Firebase/firebaseInit";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import InputAdornment from "@material-ui/core/InputAdornment";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51HOzj5DRFhV6lbF0la7CfLFxNmm4r9bh8WCo1vhAEEa3msR95IHz7wMkE8UlIrtpRmmVOwCDSrbohxjwDbGip7k600U5Ezyyx9"
);

const samplePricing = {
  international: { chips: ["$5", "$20", "$50", "$100"], currency: "$" },
  india: { chips: ["₹100", "₹500", "₹1000", "₹5000"], currency: "₹" },
};

export default function DonationPage() {
  const appConfig = useContext(AppName);
  const [donationsData, setDonationsData] = useState();
  const [alignment, setAlignment] = React.useState("international");
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(false);
  //   const [message, setMessage] = useState();

  //   useEffect(() => {
  //     // Check to see if this is a redirect back from Checkout
  //     const query = new URLSearchParams(window.location.search);
  //     if (query.get("success")) {
  //       setMessage("Order placed! You will receive an email confirmation.");
  //     }
  //     if (query.get("canceled")) {
  //       setMessage(
  //         "Order canceled -- continue to shop around and checkout when you're ready."
  //       );
  //     }
  //   }, []);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setName(samplePricing[newAlignment].chips[1].substring(1));
    }
  };

  const onChipClick = (chip) => {
    const price = chip.target.textContent.substring(1);
    console.log(price);
    setName(price);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const onCheckout = async () => {
    setDisabled(true);
    //On Checkout
    // const stripe = await stripePromise;
    const stripe = await stripePromise;
    // Call your backend to create the Checkout Session
    const data = {
      client_id: appConfig.influencer_id,
      currency_type: alignment,
      amount_type: "user_defined",
      amount: name,
      product_path:
        COLLECTION_USER +
        "/" +
        appConfig.influencer_id +
        "/" +
        SUB_COLLECTION_DONATION +
        "/" +
        donationsData[0].product_id,
    };

    // "http://localhost:5000/stripe/create-session",
    const response = await fetch(
      "https://stripe-prod-6kxvcupweq-uc.a.run.app/stripe/create-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },

        body: JSON.stringify(data),
      }
    );

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      alert("There was some error, Please try again after sometime");
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  useEffect(() => {
    const db = firebase.firestore();
    const arrayToReturn = [];
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_DONATION)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const temp = doc.data();
          arrayToReturn.push(temp);
        });
        setDonationsData(arrayToReturn);
      });
  }, []);

  return (
    <Container maxWidth="xl">
      <br />
      <Grid
        container
        spacing={2}
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2" style={{ fontWeight: "600" }}>
            Support Us
          </Typography>
        </Grid>
        {donationsData?.map((donation, index) => {
          return (
            <Grid item xs={12} key={index}>
              <center>
                <img
                  src={donation.product_images[0]}
                  style={{
                    height: "30vh",
                    width: "auto",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 11px -3px rgba(0,0,0,0.75)",
                  }}
                />
              </center>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <center>
            {/* <Paper> */}
            <div>
              <ToggleButtonGroup
                size="small"
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                style={{ color: "black" }}
              >
                <ToggleButton value="india" aria-label="left aligned">
                  India
                </ToggleButton>
                <ToggleButton value="international" aria-label="centered">
                  International
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {/* </Paper> */}
            <Typography variant="caption">
              *While paying in dollars your address needs to be outside india
            </Typography>
          </center>
        </Grid>

        <Grid item xs={12}>
          <center>
            <div>
              {samplePricing[alignment].chips?.map((price) => {
                return <Chip label={price} clickable onClick={onChipClick} />;
              })}
            </div>
          </center>
        </Grid>
        <Grid item xs={12}>
          <center>
            <TextField
              id="outlined-basic"
              label="Custom Amount"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {samplePricing[alignment].currency}
                  </InputAdornment>
                ),
              }}
              value={name}
              onChange={handleChange}
            />
          </center>
        </Grid>

        <Grid item xs={12}>
          <center>
            <Typography variant="h5" style={{}}>
              If you like what we are doing, you can support us directly by
              helping us with translation and production cost
            </Typography>
          </center>
        </Grid>
        <Grid item xs={12}>
          <center>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onCheckout}
              disabled={disabled}
            >
              Checkout
            </Button>
          </center>
        </Grid>
      </Grid>
    </Container>
  );
}
