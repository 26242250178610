import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
  lighten,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import { teal, grey, cyan } from "@material-ui/core/colors";
// import CreateAppView from "./VIewTemplate/createAppView";
// import ChannelIdView from "./VIewTemplate/channelIdView";
// import WorkSelector from "./VIewTemplate/workSelector";

import { ViewTemplateController } from "./VIewTemplate";
import { InfluencerTemplate } from "./InfluencerTemplate";
import { SearchResults } from "./InfluencerTemplate/Search";
import {
  ShopPage,
  AppointmentPage,
  // ComingSoon,
  AboutPage,
  ShortVideos,
  GridPlayView,
  DownloadApp,
  VideoPlaylistView,
  CardStylePlay,
  FullScreenPLayer,
  MeditationPage,
  IntroPage,
} from "./InfluencerTemplate/Pages";
import SignIn from "../YouTubeTemplate/InfluencerTemplate/SignIn/signin";

// import CssBaseline from "@material-ui/core/CssBaseline";
// import { AuthUserStatefulProvider } from "../Context/authUserStatefulContext";
// import PrivateRoute from "../UtilsComponent/privateRoutes";

import MessagingInit from "../Firebase/messagingInit";
import PrivacyPolicy from "./InfluencerTemplate/Pages/privacyPolicy";
import ExclusivePage from "./InfluencerTemplate/Pages/exclusivePage";
import TOS from "./InfluencerTemplate/Pages/tos";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Routes from "./routes";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },

  typography: {
    fontFamily: `"Open Sans", sans-serif`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },

  shape: {
    webkitBorderRadius: 8,
    borderRadius: 8,
    backgroundClip: "padding-box",
  },
  shadows: [],
  // props: {
  //   MuiTab: {
  //     disableRipple: true
  //   }
  // },
  // mixins: {
  //   toolbar: {
  //     minHeight: 48
  //   }
  // }
});

//Typography Initilization

theme.typography.h1 = {
  fontSize: "1.2rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h2 = {
  fontSize: "1.2rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h3 = {
  fontSize: "1rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};

theme.typography.h4 = {
  fontSize: "1rem",
  // fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};

theme.typography.h5 = {
  fontSize: "1rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.caption = {
  fontSize: "1rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.65rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",
  },
};

theme.typography.button = {
  fontSize: "1rem",
  fontWeight: 500,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.70rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.65rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",
  },
};
// theme.typography.h4 = {
//   fontSize: "1.2rem",
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "1.5rem"
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "1.25rem"
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "1rem"
//   }
// };

theme = {
  ...theme,
  overrides: {
    MuiAppBar: {
      root: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiButton: {
      root: {
        // borderRadius: 20,
      },
      label: {
        // textTransform: "none",
      },
      // contained: {
      //   boxShadow: "none",
      //   "&:active": {
      //     boxShadow: "none",
      //   },
      // },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.black,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 6,
        backgroundColor: lighten(teal[400], 0.5),
        borderRadius: 20,
      },
      bar: {
        borderRadius: 20,
        backgroundColor: teal[400],
      },
    },
  },
};

// theme = responsiveFontSizes(theme);

const drawerWidth = 256;

function Paperbase(props) {
  // const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <AuthUserStatefulProvider> */}
      <MessagingInit></MessagingInit>
      <Router>
        <Routes />
      </Router>
      {/* <CssBaseline></CssBaseline> */}
      {/* <div className={classes.root}></div> */}
      {/* <ViewTemplateController></ViewTemplateController> */}
      {/* </AuthUserStatefulProvider> */}
    </ThemeProvider>
  );
}

// Paperbase.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(Paperbase);
export default Paperbase;
