import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Divider, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { VideoCard } from "../Cards";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import {
  COLLECTION_USER,
  SUB_COLLECTION_YOUTUBE_PLAYLIST,
} from "../../../../Static/appContstants";
import axios from "axios";
import { SeeAllFullScreen } from "../Dialogue/";
import firebase from "../../../Firebase/firebaseInit";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import { useHistory } from "react-router-dom";

const arr = [0, 1, 2];

export default function CarousalTemplate(props) {
  const [videos, setVideos] = useState();
  const [playlistData, setPlaylistData] = useState();
  const appConfig = useContext(AppName);
  const history = useHistory();

  // const

  useEffect(() => {
    const db = firebase.firestore();
    const query = db
      .collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_YOUTUBE_PLAYLIST)
      .doc(props.playelistInfo)
      .get()
      .then((doc) => {
        setPlaylistData(doc.data());
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      // playlistId: props.playelistInfo.id,
      playlistId: props.playelistInfo,
      from: 0,
      size: 10,
    };

    axios
      .post(URL_GET_PLAYLIST_VIDEOS, data, {
        headers: headers,
      })
      .then((response) => {
        setVideos(response.data);
        props.loaded();
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.playelistInfo]);

  const SeeAll = (
    <SeeAllFullScreen
      linkText="See All"
      idFromFetch={props.playelistInfo}
      urlLink={URL_GET_PLAYLIST_VIDEOS}
      primaryText={playlistData?.title}
      secondaryText="Just Testing see all"
    ></SeeAllFullScreen>
  );

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          {/* {props.playelistInfo.title} */}
          {}
          {playlistData ? (
            playlistData.modified_title ? (
              playlistData.modified_title
            ) : (
              playlistData.title
            )
          ) : (
            <Skeleton variant="text" />
          )}
          {/* <Skeleton variant="text" /> */}
        </Typography>
        <Typography
          variant="h5"
          style={{
            // fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          {/* {SeeAll} */}
          <Link
            color="primary"
            onClick={() => {
              history.push(
                "/playlist?id=" +
                  playlistData.playlistId +
                  "&primaryText=" +
                  playlistData.title +
                  "&dataSource=ES"
              );
            }}
          >
            See All
            {/* {linkText} */}
            {/* See All */}
          </Link>
        </Typography>
      </div>
      {/* <Typography variant="caption">Trending in last few hours</Typography> */}

      <div
        style={{
          overflowX: "auto",
          marginBottom: 0,
          overflowY: "hidden",
          // display: "inline-flex",
        }}
      >
        <Grid container spacing={2} wrap="nowrap">
          {videos
            ? videos.map((video, index) => {
                return (
                  <Grid
                    item
                    xs={11}
                    sm={4}
                    md={3}
                    lg={2}
                    style={{
                      flex: "0 0 auto",
                      webkitOverflowScrolling: "touch",
                    }}
                    key={index}
                    // onClick={() =>
                    //   history.push(
                    //     "/playlist?id=" +
                    //       playlist_id +
                    //       "&dataSource=ES" +
                    //       "&video_id=" +
                    //       videoData.videoID
                    //   )
                    // }
                    onClick={() =>
                      history.push(
                        "/playlist?id=" +
                          playlistData.playlistId +
                          "&primaryText=" +
                          playlistData.title +
                          "&dataSource=ES" +
                          "&video_id=" +
                          video._source.videoID
                      )
                    }
                  >
                    <VideoCard
                      data={video}
                      playlist_id={props.playelistInfo}
                      description
                    ></VideoCard>
                  </Grid>
                );
              })
            : arr.map((value, index) => {
                return (
                  <Grid
                    item
                    xs={8}
                    sm={4}
                    md={3}
                    lg={2}
                    style={{
                      flex: "0 0 auto",
                      webkitOverflowScrolling: "touch",
                    }}
                    key={index}
                  >
                    <Skeleton
                      variant="rect"
                      height={150}
                      width={180}
                    ></Skeleton>
                  </Grid>
                );
              })}

          {/* {!videos &&
            arr.map((value, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                  key={index}
                >
                  <Skeleton variant="rect" height={150} width={180}></Skeleton>
                </Grid>
              );
            })} */}

          {/* </Grid> */}
        </Grid>
      </div>
      {/* <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        draggable={false}
        minimumTouchDrag="25"
      >
        {videos ? (
          videos.map((video) => {
            return <VideoCard data={video} titleDown description></VideoCard>;
          })
        ) : (
          <Skeleton></Skeleton>
        )}
      </Carousel> */}
    </React.Fragment>
  );
}
