import React, { useState } from "react";
import { Typography, Fab, Button, IconButton } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { PlayArrow, Pause, ArrowBack } from "@material-ui/icons";
import { UniversalVideoPlayer } from "../../../UniversalVideoPlayer";
import { makeStyles } from "@material-ui/styles";
import { convertMS } from "../../../../../Static/utilsFunc";
import { useHistory } from "react-router-dom";
import { iOS } from "../../../../../Static/utilsFunc";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,

  marqueeContent: {
    animation: "$scrolling 10s linear infinite",
  },

  "@keyframes scrolling": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-50px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
}));

// const timeToAdd = 1200;
const timeToAdd = 0;
// const timeToAdd = 5;

export default function MeditationPage() {
  const classes = useStyles();
  const videoRef = React.createRef();
  const [isPlaying, setIsPlaying] = useState();

  const [mediaDuration, setMediaDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [triggerTime, setTriggerTime] = useState(false);
  const history = useHistory();

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // 1100
    if (currentTime) {
      if (mediaDuration - currentTime <= timeToAdd) {
        setTriggerTime(true);
      } else {
        setTriggerTime(false);
      }
      setCurrentTime(currentTime);
    }
    // console.log(currentTime);
  }

  function onLoadedDataGetDuration(e) {
    const currentDuration = e.target.duration;
    console.log(currentDuration);
    // const currentDurationWithOffset =
    //   currentDuration + this.state.timecodeOffset;
    // const durationInSeconds = secondsToTimecode(currentDuration);
    // setMediaDuration(parseInt(currentDuration + 1200));
    setMediaDuration(parseInt(currentDuration + timeToAdd));
    setVideoLoaded(true);
  }

  function pauseMedia() {
    // setIsPlaying(false);
    videoRef.current.pause();
    setIsPlaying(false);
  }

  function playMedia() {
    // setIsPlaying(true);
    videoRef.current.play();
    setIsPlaying(true);
  }

  function togglePlayPause() {
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  }

  function handleClickOpen() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "pranayama",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <>
      <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
        <div
          style={{ zIndex: 0, height: 200, width: 200, position: "absolute" }}
        >
          <UniversalVideoPlayer
            videoRef={videoRef}
            onTimeUpdate={handleTimeUpdate}
            onLoadedDataGetDuration={onLoadedDataGetDuration}
            // controls={true}
            //   onClick={handlePlayMedia}
            // mediaUrl={"https://www.youtube.com/watch?v=" + props.data.videoID}
            // mediaUrl={
            //   "https://storage.googleapis.com/app-generator-admin-featured/audio/%20xyncAaix2LZWSZOgpRvqNVUcv3t2/kriyaCountingHighhq.mp3"
            // }
            mediaUrl={
              iOS()
                ? "https://storage.googleapis.com/app-generator-admin-featured/audio/%20xyncAaix2LZWSZOgpRvqNVUcv3t2/crakrakriya3.aac"
                : "https://storage.googleapis.com/app-generator-admin-featured/audio/%20xyncAaix2LZWSZOgpRvqNVUcv3t2/PranCounts.webm"
            }
            // mediaUrl={
            //   "https://storage.googleapis.com/app-generator-admin-featured/audio/%20xyncAaix2LZWSZOgpRvqNVUcv3t2/demo.mp3"
            // }
          ></UniversalVideoPlayer>
        </div>

        <div
          style={{
            paddingTop: 8,
            color: "white",
            zIndex: 4,
            position: "absolute",
          }}
          onClick={() => {
            history.goBack();
          }}
        >
          <IconButton>
            <ArrowBack style={{ color: "white" }} />
          </IconButton>
        </div>

        <div
          style={{
            width: "100vw",
            textAlign: "center",
            paddingTop: 30,
            color: "white",
            zIndex: 3,
            position: "absolute",
          }}
        >
          <Typography variant="h2" style={{ fontWeight: "600" }}>
            Sudarshan Chakra Kriya
          </Typography>
        </div>
        <div
          style={{
            // height: "100vh",
            width: "100vw",
            textAlign: "center",
            justifyContent: "center",
            zIndex: 3,
            top: "30vh",
            position: "absolute",
          }}
        >
          <Fab
            color="secondary"
            aria-label="add"
            size="large"
            style={{
              height: "120px",
              width: "120px",
              boxShadow: "-1px 4px 16px -5px rgba(122,117,122,0.8)",
              background: grey[300],
              color: "black",
            }}
            onClick={togglePlayPause}
          >
            {!isPlaying ? (
              <PlayArrow style={{ height: "80px", width: "80px" }} />
            ) : (
              <Pause style={{ height: "80px", width: "80px" }} />
            )}
          </Fab>

          {!triggerTime ? (
            <Typography style={{ padding: 16, color: "white" }}>
              {convertMS(
                parseInt(mediaDuration) * 1000 - parseInt(currentTime) * 1000
              )}
            </Typography>
          ) : (
            <Typography style={{ padding: 16, color: "white" }}>
              Start Kriya
            </Typography>
          )}
          <Button variant="contained" onClick={handleClickOpen}>
            Share
          </Button>
        </div>

        <img
          src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBack1exclusiveGuruji.png"
          style={{
            height: "100vh",
            width: "100vw",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <img
          src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBack2exclusiveGuruji.png"
          style={{
            height: "100vh",
            width: "100vw",
            objectFit: "cover",
            zIndex: 2,
            position: "absolute",
          }}
        />
        <img
          src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBodyBackexclusiveGuruji.svg"
          style={{
            height: "50vh",
            width: "100vw",
            // left: "4vw",
            // left: "20vw",
            // objectFit: "cover",
            zIndex: 2,
            position: "absolute",
            bottom: 0,
            textAlign: "center",
            opacity: "50%",
            // padding: 16,
          }}
        />
        {/* <div style={{ width: "100vw", textAlign: "center" }}> */}
        <img
          //   src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBodyexclusiveGuruji.svg"
          src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbAsset%201pranayamBoy.svg"
          className={classes.marqueeContent}
          style={{
            height: "30vh",
            width: "100vw",
            //   left: "20vw",
            // objectFit: "cover",
            zIndex: 4,
            position: "absolute",
            bottom: 0,
            alignContent: "center",
            opacity: "90%",
            // padding: 16,
          }}
        />
        {/* </div> */}
      </div>
    </>
  );
}
