import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Grid,
  Link,
  Container,
  Divider,
  Avatar,
  IconButton,
} from "@material-ui/core/";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";

import Slide from "@material-ui/core/Slide";

import { BottomAppBar } from "../AppBar";
import {
  SUB_COLLECTION_MERCHANDISE_CATEGORY,
  COLLECTION_USER,
} from "../../../../Static/appContstants";
import firebase from "../../../Firebase/firebaseInit";
import AppName from "../../../ReactContext/AppNameContext";
import ShopCategoryContainer from "../Containers/shopCategoryContainer";
import NotifButton from "../UtilsComponent/notificationButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
  },
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 20,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const arr = [
//   "merchandise1.webp",
//   "merchandise2.jpg",
//   "merchandise3.webp",
//   "merchandise4.webp",
//   "merchandise5.webp",
//   "merchandise6.webp",
//   "merchandise7.webp",
// ];

export default function ShopPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { linkText, urlLink, idFromFetch, primaryText, secondaryText } = props;
  // const [videos, setVideos] = useState();

  const [categories, setCategories] = useState();
  const appConfig = useContext(AppName);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_MERCHANDISE_CATEGORY)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayToPush.push(doc.data());
          console.log(doc.id, " => ", doc.data());
        });
        setCategories(arrayToPush);
      });
  }, []);

  return (
    <div>
      <div className={classes.toolbar} />
      <AppBar
        className={classes.appBar}
        color={"inherit"}
        position="static"
        style={{ background: "transparent" }}
      >
        <Toolbar>
          <Typography variant="h1" className={classes.title}>
            Shop
          </Typography>
          <NotifButton />

          {/* <Avatar className={classes.large}>
            <IconButton>
              <FaceIcon />
            </IconButton>
          </Avatar> */}
        </Toolbar>
      </AppBar>

      {/* <div className={classes.toolbar} /> */}

      <Container maxWidth="xl" style={{ paddingBottom: 50 }}>
        <Divider light></Divider>

        {categories?.map((category, index) => {
          return (
            <div key={index}>
              <ShopCategoryContainer
                data={category}
                // loaded={loaded}
              ></ShopCategoryContainer>
              <Divider light></Divider>
            </div>
          );
        })}
      </Container>
      <BottomAppBar selectedMenu="Shop"></BottomAppBar>
    </div>
  );
}

// FullScreenDialogSeeAll.propTypes = {
//   linkText: PropTypes.string.isRequired,
//   idFromFetch: PropTypes.string.isRequired,
//   urlLink: PropTypes.string.isRequired,
//   primaryText: PropTypes.string.isRequired,
//   secondaryText: PropTypes.string.isRequired,
// };
