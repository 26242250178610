import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import Paper from "@material-ui/core/Paper";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { grey, green } from "@material-ui/core/colors";
import { CustomSlider } from "../UtilsComponent";
import FullScreenDialogueVideoPlayer from "../Dialogue/fullScreenDiaogueVideoPlayer";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {
  ArrowBackSharp,
  PlayCircleOutline,
  WhatsApp,
} from "@material-ui/icons";

// const width = 300;
const width = 250;
const height = 150;

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,

  // },
  root: {
    // maxWidth: 345,
    boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
    marginBottom: 16,
  },
  wrapper: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    overflow: "hidden",
    // height: height,
    // width: width,
    //     -webkit-box-shadow: -1px 10px 9px -6px rgba(122,117,122,0.8);
    // -moz-box-shadow: -1px 10px 9px -6px rgba(122,117,122,0.8);
    boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
  },
  backGradient: {
    // background: "rgb(2,0,36)",
    // background:
    //   "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,7,255,0) 49%, rgba(249,249,249,0) 87%)",
    background: "linear-gradient(0deg,black,transparent 50%)",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function SearchVideoCard(props) {
  const classes = useStyles();
  const [showVideo, setShowVideo] = useState(false);
  const [videoData, setVideoData] = useState(props.data);

  const [marks, setMarks] = useState([]);
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [dialogueArray, setDialogueArray] = useState([
    { text: props.data.text, startTime: props.data.startTime },
  ]);

  const [open, setOpen] = useState(false);
  // const { data } = props;

  useEffect(() => {
    const tempMarks = [];
    const dialogueText = [];
    let count = 1;

    // //Best Match set Time
    // setCurrentTime(props.data.startTime / 1000);

    //Setting All the Marks && Setting Total Count && Setting consolidated dialogue array
    tempMarks.push({ value: props.data.startTime / 1000 });

    // dialogueText.push({
    //   text: props.data.highlight.dialog[0]
    //     .replace(/<em>/g, "<b>")
    //     .replace(/<\/em>/g, "</b>"),
    //   startTime: props.data.startTime,
    // });

    props.data.extraMatches.map((item) => {
      tempMarks.push({ value: item.startTime / 1000 });
      //   count++;
      //   dialogueText.push({
      //     text: item.highlight.dialog[0]
      //       .replace(/<em>/g, "<b>")
      //       .replace(/<\/em>/g, "</b>"),
      //     startTime: item.startTime,
      //   });
    });

    setMarks(tempMarks);
    // setTotalResultCount(count);
    // setDialogueArray(dialogueText);
  }, [props.data]);

  function showVideoToggle() {
    if (showVideo) {
      setShowVideo(false);
    } else {
      setShowVideo(true);
    }
  }

  function openDialogue() {
    // alert("Clicked");
    open
      ? setTimeout(() => {
          setOpen(false);
        }, 100)
      : setOpen(true);
  }

  function handleClickOpen() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "app",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center",
              top: "40%",
              left: "36%",
              zIndex: 3,
            }}
            onClick={openDialogue}
          >
            <Button
              variant="contained"
              startIcon={<PlayCircleOutline />}
              style={{ background: "black", color: "white" }}
            >
              {/* <Typography >Preview</Typography> */}
              Spot Play
            </Button>
          </div>
          <div
            style={{
              bottom: 0,
              padding: 8,
              position: "absolute",
              zIndex: 100,
              width: "100%",
            }}
          >
            <CustomSlider
              aria-label="ios slider"
              // defaultValue={60}
              // marks={marks}
              marks={marks}
              valueLabelDisplay="off"
              // value={props.currentTime}
              min="0"
              // max={props.data.MEDIA_DURATION_SECS}
              max={1000}
              // onChange={handleProgressBarClickSlider}
              // onClick={handleOnClick}
            />
          </div>
          {/* Overlay */}
          <div
            style={{
              //   position: "relative",
              position: "absolute",
              // background:
              //   "linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.44) 33%,rgba(0,0,0,0.33) 50%,rgba(0,0,0,0.28) 58%,rgba(0,0,0,0) 100%)",
              background: "linear-gradient(0deg,black,transparent 70%)",
              zIndex: 2,
              width: "100%",
              height: "100%",
            }}
          ></div>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="auto"
            width="100%"
            image={"https://i3.ytimg.com/vi/" + videoData.videoID + "/mq2.jpg"}
            //   image="/static/images/cards/contemplative-reptile.jpg"
            title={videoData.title}
            onClick={openDialogue}
          />
        </div>

        {videoData.title && (
          <CardContent>
            <Typography variant="h5" component="span">
              {videoData.title}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">
              {"Conversations between Digvijay ji and " +
                props.primary_text +
                " and experiences in the path of spirituality, knowledge discourses and sessions."}
            </Typography> */}
          </CardContent>
        )}
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary" onClick={openDialogue}>
          Watch Full
        </Button> */}
        <Button
          size="medium"
          //   variant="contained"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<WhatsApp />}
          style={{ color: green[600], flexGrow: 1 }}
        >
          Share
        </Button>
        {/* <Button size="small" color="primary" onClick={handleClickOpen}>
          Share
        </Button> */}
      </CardActions>
      {open && (
        <FullScreenDialogueVideoPlayer
          open={open}
          data={props.data}
        ></FullScreenDialogueVideoPlayer>
      )}
    </Card>
  );
}

//   return (
//     <React.Fragment>
//       <div className={classes.wrapper}>
//         <Paper className={classes.paper}>
//           <div
//             className={classes.backGradient}
//             style={{ position: "relative" }}
//           >
//             <img
//               src={
//                 "https://i3.ytimg.com/vi/" +
//                 videoData.videoID +
//                 "/hqdefault.jpg"
//               }
//               style={{ objectFit: "cover" }}
//               // width={width}
//               height={height}
//               width="100%"
//               // height={height}
//             ></img>

//             {props.titleCover && (
//               <React.Fragment>
//                 <div
//                   className={classes.backGradient}
//                   style={{
//                     height: height,
//                     width: "100%",
//                     // zIndex: ,
//                     position: "absolute",
//                     top: 0,
//                   }}
//                 ></div>
//                 <Typography
//                   style={{
//                     position: "absolute",
//                     bottom: "5%",
//                     left: "2%",
//                     // zIndex: 2,
//                     color: "white",
//                   }}
//                   variant="h5"
//                 >
//                   {videoData.title}
//                 </Typography>
//               </React.Fragment>
//             )}

//             <div
//               style={{
//                 bottom: 0,
//                 padding: 8,
//                 position: "absolute",
//                 zIndex: 100,
//                 width: "100%",
//               }}
//             >
//               <CustomSlider
//                 aria-label="ios slider"
//                 // defaultValue={60}
//                 // marks={marks}
//                 marks={marks}
//                 valueLabelDisplay="off"
//                 // value={props.currentTime}
//                 min="0"
//                 // max={props.data.MEDIA_DURATION_SECS}
//                 max={1000}
//                 // onChange={handleProgressBarClickSlider}
//                 // onClick={handleOnClick}
//               />
//             </div>
//             <div
//               className={classes.backGradient}
//               onClick={openDialogue}
//               style={{
//                 height: height,
//                 width: "100%",

//                 position: "absolute",
//                 top: 0,
//               }}
//             ></div>
//           </div>
//         </Paper>
//         <div style={{ paddingTop: "8px" }}>
//           {props.titleDown && (
//             <Typography
//               component="div"
//               variant="subtitle2"
//               style={{ fontWeight: 400 }}
//             >
//               <Box>{videoData.title}</Box>
//             </Typography>
//           )}

//           {props.description && (
//             <Typography variant="caption" style={{ color: grey[500] }}>
//               <Box>{videoData.description}</Box>
//             </Typography>
//           )}
//         </div>
//         {open && (
//           <FullScreenDialogueVideoPlayer
//             open={open}
//             data={props.data}
//           ></FullScreenDialogueVideoPlayer>
//         )}
//       </div>
//     </React.Fragment>
//   );
// }
