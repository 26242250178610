import React, { Component } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function HomeScreenButton() {
  const history = useHistory();

  return (
    <>
      <Typography
        variant="h3"
        style={{
          fontWeight: 600,
          paddingTop: 16,
          paddingBottom: 16,
          flexGrow: 1,
        }}
      >
        Quick Access
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            // size="large"
            style={{
              width: "100%",
              boxShadow: "-1px 2px 8px -5px rgba(122,117,122,0.8)",

              //   background: "-webkit-linear-gradient(to right, #0083B0, #00B4DB)",
              //   background: "linear-gradient(to right, #0083B0, #00B4DB)",
              //   background: "black",
              //   color: "white",
            }}
            onClick={() => history.push("/meditation")}
          >
            Daily Sadhana
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            // size="large"
            style={{
              width: "100%",
              boxShadow: "-1px 2px 8px -5px rgba(122,117,122,0.8)",
              //   background: "rgb(115,186,151)",
              //   background:
              //     "linear-gradient(90deg, rgba(115,186,151,1) 6%, rgba(0,224,224,1) 59%)",
              //   color: "white",
            }}
            onClick={() =>
              window.open(
                "https://wa.me/918600033323?text=I have a Course Enquiry"
              )
            }
          >
            Course Enquiry
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            // size="large"
            style={{
              width: "100%",
              boxShadow: "-1px 2px 8px -5px rgba(122,117,122,0.8)",
              //   background: "-webkit-linear-gradient(to right, #6dd5ed, #2193b0)",
              //   background: "linear-gradient(to right, #6dd5ed, #2193b0)",
              //   color: "white",
            }}
            onClick={() =>
              history.push(
                "/search?searchString=Anand%20Rajendran%20ji%20ki%20Japan%20story"
              )
            }
          >
            Voice Search
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            // size="large"
            style={{
              width: "100%",
              boxShadow: "-1px 2px 8px -5px rgba(122,117,122,0.8)",
              //   background: "-webkit-linear-gradient(to right, #78ffd6, #007991)",
              //   background: "linear-gradient(to right, #78ffd6, #007991)",
              //   color: "white",
            }}
            onClick={() => history.push("exclusive")}
          >
            Exclusive
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
