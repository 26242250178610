import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Grid,
  Link,
  Container,
  Divider,
  Avatar,
  IconButton,
} from "@material-ui/core/";
import { FaceOutlined as FaceIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { ScheduleCard } from "../Cards";
import { BottomAppBar } from "../AppBar";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "transparent",
    // position: "",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
  },
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 20,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopPage(props) {
  const classes = useStyles();

  const { linkText, urlLink, idFromFetch, primaryText, secondaryText } = props;

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <AppBar className={classes.appBar} color={"inherit"} position="static">
        <Toolbar>
          <Typography variant="h1" className={classes.title}>
            {primaryText}
          </Typography>

          {/* <Avatar className={classes.large}>
            <IconButton>
              <FaceIcon />
            </IconButton>
          </Avatar> */}
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        <Divider light />
      </Container>
    </React.Fragment>
  );
}

// FullScreenDialogSeeAll.propTypes = {
//   linkText: PropTypes.string.isRequired,
//   idFromFetch: PropTypes.string.isRequired,
//   urlLink: PropTypes.string.isRequired,
//   primaryText: PropTypes.string.isRequired,
//   secondaryText: PropTypes.string.isRequired,
// };
