import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Typography, Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const width = 400;
const height = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    overflow: "hidden",
    marginLeft: 20,
    // height: height,
    // width: width,
    boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
  },
  backGradient: {
    background: "rgb(2,0,36)",
    background:
      "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,7,255,0) 49%, rgba(249,249,249,0) 87%)",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function StoryCard(props) {
  const classes = useStyles();
  //   const [showVideo, setShowVideo] = useState(false);
  const { featured_action_image_url } = props;
  const { featured_action_text, featured_action_url } = props.data;

  return (
    <Paper className={classes.paper}>
      <div className={classes.backGradient} style={{ position: "relative" }}>
        <img
          src={featured_action_image_url}
          style={{ objectFit: "cover" }}
          // width={width}
          height={height}
          width="100%"
          // height="100%"
        ></img>

        <Paper
          style={{
            background: "black",
            position: "absolute",
            bottom: "5%",
            right: "5%",
            padding: "5px",
          }}
        >
          {/* <Button variant="contained" color="black"> */}

          <Link
            href={featured_action_url}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
          >
            <Typography variant="caption" style={{ color: "white" }}>
              {featured_action_text ? featured_action_text : "Get Started"}
            </Typography>
          </Link>

          {/* </Button> */}
        </Paper>
      </div>

      {/* <UniversalVideoPlayer mediaUrl="https://www.youtube.com/watch?v=H9fjhQMsDW4"></UniversalVideoPlayer> */}
      {/* <Typography variant="h3">Obama Meets Donald</Typography>
      <Typography variant="caption">A little bit of description</Typography> */}
    </Paper>
  );
}
