import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Paper } from "@material-ui/core";
import { Typography, Link } from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import { green, lime } from "@material-ui/core/colors";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  status: {
    fontWeight: 200,
  },
  statusGreen: {
    color: green[800],
  },
  cardTitle: {},
  cardStatus: {},
  cardChip: {},
  cardActionButton: {},
  cardAction: {},
});

const ModPaper = withStyles({
  root: {
    backgroundColor: "transparent",
    // padding: 12,
    // borderRadius: 20
    // width: 700,
  },
})(Paper);

const ModLinearProgress = withStyles({
  root: {
    backgroundColor: lime[300],
    height: 8,
  },
  bar: {
    backgroundColor: lime[600],
  },
})(LinearProgress);

export default function Merchandise(props) {
  const classes = useStyles();
  const { product_id, title, url, price } = props.data;
  const history = useHistory();

  function onBuyClick(url) {
    window.open(url);
    // history.push(url);
  }

  return (
    <ModPaper>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 16, width: "40%" }}>
          {/* <Skeleton variant="rect" width={150} height={145} /> */}
          <img
            // src={
            //   "https://storage.googleapis.com/deeptrans-test-static-assets/AppGeneratorStaticFiles/" +
            //   merchandiseID
            // }
            src={
              "https://storage.googleapis.com/app-generator-admin-featured/product_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/" +
              product_id
            }
            style={{
              objectFit: "cover",
              height: 130,
              // width: 150,
              // height: "100%",
              width: "100%",
              borderRadius: 10,
            }}
          ></img>
        </div>
        <div style={{ width: "60%" }}>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            // gutterBottom
          >
            {title}
          </Typography>
          {/* <Typography variant="caption" component={"div"} gutterBottom>
            A Tshirt that matters
          </Typography> */}

          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontWeight: 400,
              //   fontSize: 20,
              // marginBottom: 8,
              // marginTop: 8,
              color: green[800],
            }}
          >
            ₹ {price}
          </Typography>
          {/* <br></br> */}
          {/* <Chip
              size="small"
              style={{ backgroundColor: green[50], color: green[800] }}
              label={
                <Typography
                  style={{ fontWeight: 400, fontSize: 10 }}
                  variant="caption"
                >
                  English
                </Typography>
              }
            ></Chip> */}

          <Button
            variant="contained"
            color="default"
            style={{ bottom: 0 }}
            onClick={() => {
              onBuyClick(url);
            }}
          >
            {/* <Link href={url}> */}
            <Typography variant={"subtitle"}>Buy Now</Typography>
            {/* </Link> */}
          </Button>
        </div>
      </div>
    </ModPaper>
  );
}
