import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";

// import { Typography } from "@material-ui/core";
import {
  Divider,
  Typography,
  Paper,
  Button,
  Link,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { green, grey } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";

import { ExclusiveCarousel, PopularTeacher } from "../Carousel";

import { AppBar, BottomAppBar, TopAppBar } from "../AppBar";
import withAuthnetication from "../../../session/withAuthentication";

import {
  SUB_COLLECTION_EXCLUSIVE_PLAYLIST,
  COLLECTION_USER,
} from "../../../../Static/appContstants";

import {
  StarBorderOutlined,
  MovieOutlined,
  PlayCircleFilledWhiteOutlined,
} from "@material-ui/icons";
import NotifButton from "../UtilsComponent/notificationButton";

// import App from "../../../App";
import AppName from "../../../ReactContext/AppNameContext";
// import { useBottomScrollListener } from "react-bottom-scroll-listener";

import firebase from "../../../Firebase/firebaseInit";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 30,
  },
  marqueeContent: {
    animation: "$scrolling 20s linear infinite",
  },

  "@keyframes scrolling": {
    "0%": {
      transform: "translateX(0vw)",
    },
    "100%": {
      transform: "translateX(-170vw)",
    },
  },
}));

const exlArr = [
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji1.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji2.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji3.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji4.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji5.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji6.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji7.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji8.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji9.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji1.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/raregururji2.jpg",
];

const arr = [0, 1, 2];

// export default withAuthnetication(Wrapper);

export default function Wrapper() {
  const classes = useStyles();
  const appConfig = useContext(AppName);
  const [playelistsInfo, setPlayelistsInfo] = useState();

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_PLAYLIST)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (!doc.data().display_at) {
            arrayToPush.push(doc.data());
          }

          //   console.log(doc.id, " => ", doc.data());
        });
        setPlayelistsInfo(arrayToPush);
      });
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          right: 4,
          top: 4,
          position: "fixed",
          zIndex: 5,
          background: "linear-gradient(transparent, black)",
        }}
      >
        <NotifButton color="white" />
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            bottom: "-20px",
            zIndex: 3,
            padding: 16,
            // color: "black",
            color: grey[900],
          }}
        >
          <div
            style={{
              alignItems: "center",
              height: -1,
              display: "flex",
              color: "black",
            }}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Exclusive
            </Typography>
          </div>
          <div style={{ alignItems: "center", height: -1, display: "flex" }}>
            <StarBorderOutlined />
            <Typography variant="h5">
              &nbsp; &nbsp;Premium movie experience
            </Typography>
          </div>
          <div style={{ alignItems: "center", height: -1, display: "flex" }}>
            <MovieOutlined />
            <Typography variant="h5">
              &nbsp; &nbsp;Never seen before Glimpses
            </Typography>
          </div>
          <div style={{ alignItems: "center", height: -1, display: "flex" }}>
            <PlayCircleFilledWhiteOutlined />
            <Typography variant="h5">
              &nbsp; &nbsp;No youtube redirection
            </Typography>
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(transparent, #fafafa)",
            bottom: 0,
            width: "100%",
            height: 80,
            position: "absolute",
            zIndex: 2,
          }}
        ></div>
        <div
          style={{
            overflowX: "auto",
            marginBottom: 16,
            overflowY: "hidden",
            width: "100vw",
            height: "auto",
          }}
        >
          <Grid
            container
            spacing={0}
            wrap="nowrap"
            className={classes.marqueeContent}
          >
            {exlArr
              ? exlArr.map((value, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={2}
                      md={1}
                      lg={1}
                      style={{
                        flex: "0 0 auto",
                        webkitOverflowScrolling: "touch",
                      }}
                      key={index}
                    >
                      <img
                        src={value}
                        style={{
                          objectFit: "cover",
                          maxHeight: 250,
                          maxWidth: "100%",
                          height: 250,
                          width: "100%",
                        }}
                      />
                    </Grid>
                  );
                })
              : arr.map((value, index) => {
                  return (
                    <Grid
                      item
                      xs={8}
                      sm={4}
                      md={3}
                      lg={2}
                      style={{
                        flex: "0 0 auto",
                        webkitOverflowScrolling: "touch",
                      }}
                      key={index}
                    >
                      <Skeleton
                        variant="rect"
                        height={200}
                        width={180}
                      ></Skeleton>
                    </Grid>
                  );
                })}
          </Grid>
        </div>
      </div>

      <Container maxWidth="xl" style={{ paddingBottom: "30vh" }}>
        {/* {playelistsInfo?.map((playelistInfo, index) => {
          return (
            <div key={index}>
              <ExclusiveCarousel data={playelistInfo}></ExclusiveCarousel>
            </div>
          );
        })} */}
        {playelistsInfo?.map((playelistInfo, index) => {
          return (
            <div key={index}>
              <PopularTeacher
                data={playelistInfo}
                titleDown
                premium
              ></PopularTeacher>
            </div>
          );
        })}
        <br />
        <br />
        <MadeWith />
        {/* <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          Courses
        </Typography> */}
        {/* <Merchandise /> */}
        {/* {isLoading && <CircularProgress color="secondary" />} */}
      </Container>

      <BottomAppBar selectedMenu="Exclusive"></BottomAppBar>
    </React.Fragment>
  );
}

export function Merchandise(props) {
  const classes = useStyles();
  // const { product_id, title, url, price } = props.data;
  // const history = useHistory();

  function onBuyClick() {
    // history.push(url);
  }

  return (
    <Paper>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 16, width: "40%" }}>
          {/* <Skeleton variant="rect" width={150} height={145} /> */}
          <img
            src={
              "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/bachflower.jpg"
              // "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/to-delete/image.png"
            }
            style={{
              objectFit: "cover",
              height: 130,
              // width: 150,
              // height: "100%",
              width: "100%",
              borderRadius: 10,
            }}
          ></img>
        </div>
        <div style={{ width: "60%" }}>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            // gutterBottom
          >
            Batch Flower Therapy
            {/* Switch Transformation */}
          </Typography>
          <Typography variant="caption" component={"div"} gutterBottom>
            Selling ice to Eskimos’ has always been cited as the role model for
            all sales persons.
          </Typography>

          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontWeight: 400,
              color: green[800],
            }}
          >
            ₹ 999
          </Typography>

          <Button variant="contained" color="default" style={{ bottom: 0 }}>
            <Link href={"asd"}>
              <Typography style={{ fontWeight: 600 }} variant={"subtitle"}>
                Get
              </Typography>
            </Link>
          </Button>
        </div>
      </div>
    </Paper>
  );
}

function MadeWith() {
  return (
    <>
      <div style={{ margin: 16, width: "100%" }}>
        <center>
          <Typography
            component="div"
            variant="caption"
            style={{ fontWeight: 600 }}
          >
            Made with ❤ by <a href="https://aapkaapp.com"> AapkaApp.com</a>
          </Typography>

          {/* <a href="https://aapkaapp.com"> */}
          <Typography variant="caption">A Sumeru Product</Typography>
          {/* </a> */}
        </center>
      </div>
    </>
  );
}
