import React from "react";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
  lighten,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Paper, Container, IconButton } from "@material-ui/core";

import { teal, grey, cyan, deepPurple, indigo } from "@material-ui/core/colors";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "100%",
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const imageArr = [
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%202_youtube.svg",
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%203_youtube.svg",
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%204_youtube.svg",
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%205_youtube.svg",
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%206_youtube.svg",
  "https://storage.googleapis.com/deeptrans-test-static-assets/Asset%207_youtube.svg",
];

export default function WorkSelectorView(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div style={{ position: "absolute", zIndex: 2, top: "10%", left: "10%" }}>
        {/* <Container maxWidth="sm"> */}
        <div>
          <Slide in direction="down" mountOnEnter unmountOnExit>
            <Typography variant="h1" fontWeight={500}>
              Who are you
            </Typography>
          </Slide>
          <br />
          <br />
          <div style={{ height: "50%" }}>
            {/* <Grid item xs={12}> */}
            <Grid container justify="center" spacing={2}>
              {imageArr.map((value, index) => (
                <Grow
                  in
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ timeout: 500 * (index + 1) }}
                >
                  <Grid key={value} item>
                    <Paper className={classes.paper}>
                      <div
                        style={{
                          padding: "5vh",
                          textAlign: "center",
                          paddingBottom: "3vh",
                        }}
                      >
                        <img src={value} height="100" width="100"></img>
                      </div>

                      <Typography
                        variant="h4"
                        style={{ fontWeight: 300, padding: "3px" }}
                        gutterBottom
                        align="center"
                      >
                        Influencer
                      </Typography>
                    </Paper>
                  </Grid>
                </Grow>
              ))}
            </Grid>
            {/* </Grid> */}
          </div>

          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor: deepPurple["A200"] }}
            onClick={props.onClickIncrement}
          >
            Continue
          </Button>
        </div>
        {/* </Container> */}
      </div>
      {/* Background Elements */}
      {/* <img
        src="https://storage.googleapis.com/deeptrans-test-static-assets/Asset%208_youtube.svg"
        height="40%"
        width="40%"
        style={{ position: "fixed", right: "-3%", bottom: 20, zIndex: 1 }}
      ></img> */}
      <div
        style={{
          position: "fixed",
          // right: 5,
          width: "100%",
          height: "100%",
          backgroundColor: grey[50],
        }}
      ></div>
      {/* <div
        style={{
          position: "fixed",
          right: 0,
          width: "17%",
          height: "100%",
          backgroundColor: deepPurple["A200"],
        }}
      ></div> */}
    </React.Fragment>
  );
}

// Template1.propTypes = {
//   Heading: PropTypes.string,
//   ActionButtonText: PropTypes.string,
//   SideColor: PropTypes.string,
//   SideImage: PropTypes.string,
//   TextInput: PropTypes.string,
// };
