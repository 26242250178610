import React, { Component } from "react";
import { Typography } from "@material-ui/core";

export default function DownloadAppPage() {
  return (
    <div style={{ position: "relative" }}>
      <img
        src="https://storage.googleapis.com/app-generator-admin-featured/featured/xyncAaix2LZWSZOgpRvqNVUcv3t2/7d5a5a35-fe6b-4994-87d7-0b0c94ff9eb7"
        style={{ width: "100%", height: "100vh", objectFit: "cover" }}
      />
      <center>
        <div
          style={{
            position: "absolute",
            top: "40px",
            color: "white",
            width: "100%",
          }}
        >
          <center>
            <Typography variant="h4">
              Dive in devotion of journey in spiritual path
            </Typography>
            <Typography variant="h6">Guru Grace Stories</Typography>
          </center>
        </div>
        <div
          style={{
            top: "40vh",
            zIndex: 2,
            position: "absolute",
            width: "100%",
          }}
        >
          <center>
            <a href="https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={{
                  color: "white",
                  maxHeight: "100px",
                  maxWidth: "100%",
                  width: "auto",
                }}
              />
            </a>
          </center>
        </div>

        {/* <Button
          style={{ top: "40vh", zIndex: 2, position: "absolute" }}
          variant="contained"
          color="primary"
          // color="default"
          // className={classes.button}
          startIcon={<CloudUpload />}
        >
          Download
        </Button> */}
      </center>
    </div>
  );
}
