import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography, InputBase } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

//Icon Import
import MicrophoneIcon from "@material-ui/icons/KeyboardVoice";
// import MicrophoneIcon from "@material-ui/icons/SearchOutlined";

import { languageVoiceConst } from "../../../../Static/languageVoice";

// import { useDispatch } from "react-redux";

// import { setVoiceSearchString } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  avatar: {
    boxShadow: "0 2px 10px 0 rgba(0,0,0,.05)",
    border: "1px solid #e7e7e7",
    height: 100,
    width: 100,
  },
  microphoneIcon: {
    fontSize: 50,
  },
  avatarBackgroundWhite: {
    // transition: theme.transitions.create({
    //   easing: theme.transitions.easing.easeInOut
    //   //   duration: "20"
    // }),
    backgroundColor: "White",
  },
  avatarBackgroundRed: {
    // transition: theme.transitions.create({
    //   easing: theme.transitions.easing.easeInOut,
    //   duration: "50"
    // }),
    backgroundColor: red[500],
  },
  iconColorWhite: {
    color: "white",
  },
  iconColorRed: {
    color: red[500],
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  selectedLang: {
    color: blue[500],
    fontSize: 18,
  },
}));

var reInitializeFlag = false;
var selectedLangCodeTest = "en-IN";

const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isIos = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);

export default function AlertDialog(props) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState("");
  const [speechStart, setSpeechStart] = useState(false);
  const [offline, setOffline] = useState(false);

  const [recognitionObj, setRecognitionObj] = useState({});

  const [langList, setLangList] = useState(languageVoiceConst);

  const [selectedLangCode, setSelectedLangCode] = useState(
    selectedLangCodeTest
  );
  //   const [reIntializeLang, setReInitializeLang] = useState(false);
  const onVoiceSearchParent = props.onVoiceSearch;

  // useEffect(() => {
  //   if (navigator.onLine && !isIos) {
  //     if (isChrome) {
  //       const SpeechRecognition =
  //         window.SpeechRecognition || window.webkitSpeechRecognition;
  //       const recognition = new SpeechRecognition();
  //       setRecognitionObj(recognition);
  //     } else {
  //       console.log("Not Chrome");
  //     }
  //   } else {
  //     setOffline(true);
  //   }
  // }, []);

  useEffect(() => {
    if (navigator.onLine) {
      if (window.Modernizr.speechrecognition) {
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        setRecognitionObj(recognition);
      } else {
        console.log("Speech Recognition not supported");
      }
    } else {
      setOffline(true);
    }
  }, []);

  //Added for intializing language
  useEffect(() => {
    if (props.initLang) {
      // alert(props.initLang);
      selectedLangCodeTest = props.initLang;
      setSelectedLangCode(selectedLangCodeTest);
    }
  }, [props.initLang]);

  function intializeSpeechRecog() {
    handleStartRecognition();
    // recognitionObj.lang = ;
  }

  const handleClickOpen = () => {
    //call some parent method before intializing speech Recognition
    props.parentInitialization
      ? props.parentInitialization()
      : console.log("No parent Method");

    intializeSpeechRecog();
  };

  const handleClose = () => {
    // varRecognition.abort();
    recognitionObj.abort();
    setSpeechStart(false);
    setText();
    setOpen(false);
  };

  // navigator.onLine && isChrome && !isIos

  function handleStartRecognition() {
    if (navigator.onLine) {
      //   recognitionObj.interimResults = true;
      setSelectedLangCode(selectedLangCodeTest);
      recognitionObj.interimResults = true;
      //   recognitionObj.lang = selectedLangCode;
      recognitionObj.lang = selectedLangCodeTest;

      recognitionObj.abort();
      console.log("abort");
      setTimeout(function () {
        recognitionObj.start();
        setSpeechStart(true);
      }, 200);
      console.log("Start");
      recognitionObj.interimResults = (e) => {
        const current = e.resultIndex;
        const transcript = e.results[current][0].transcript;
        console.log(transcript);
        setText(transcript);
      };
      recognitionObj.onresult = (e) => {
        const current = e.resultIndex;
        const transcript = e.results[current][0].transcript;
        console.log(transcript);
        setText(transcript);
      };
      recognitionObj.onspeechstart = (e) => {
        setSpeechStart(true);
      };
      recognitionObj.onsoundstart = function () {
        setSpeechStart(true);
      };

      recognitionObj.onend = (e) => {
        setSpeechStart(false);

        //Comment out if pop up exit needed on exit speech
        // console.log("reIntializeLang: " + reInitializeFlag);
        // if (!reInitializeFlag) {
        //   setTimeout(function() {
        //     handleClose();
        //   }, 400);
        // } else {
        //   reInitializeFlag = false;
        // }
      };
      setOpen(true);
    } else {
      setOffline(true);
    }
  }

  const onClickLanguage = (event, data) => {
    // console.log(event.target.getAttribute("data-key"));
    const langCode = event.target.getAttribute("data-key");
    selectedLangCodeTest = langCode;
    setSelectedLangCode(langCode);
    // setReInitializeLang(true);
    reInitializeFlag = true;
    intializeSpeechRecog();
  };

  const onClickSpeechIcon = (event, data) => {
    reInitializeFlag = true;
    intializeSpeechRecog();
  };

  function onSearchClick() {
    //Dispatch To Redux if you want value
    // dispatch(setVoiceSearchString(text));
    handleClose();
    //text and languageCode
    console.log(text);

    onVoiceSearchParent(text, selectedLangCode);
  }

  function handleDisabledClickOpen() {
    if (isIos) {
      alert(
        "ios doesn't support voice input, Please use chrome on macOS, PC or Android"
      );
    } else {
      alert("This Browser doesn't support voice input, Please use Chrome");
    }
  }

  function handleOnTextChange(event) {
    const value = event.target.value;
    setText(value);
  }

  return (
    <div>
      {/* {isChrome ? (
        <IconButton onClick={handleClickOpen}>
          <MicrophoneIcon style={props.iconStyle ? props.iconStyle : {}} />
        </IconButton>
      ) : (
        <IconButton onClick={handleDisabledClickOpen}>
          <MicrophoneIcon style={props.iconStyle ? props.iconStyle : {}} />
        </IconButton>
      )} */}

      {window.Modernizr.speechrecognition ? (
        <IconButton onClick={handleClickOpen}>
          <MicrophoneIcon style={props.iconStyle ? props.iconStyle : {}} />
        </IconButton>
      ) : (
        <IconButton onClick={handleDisabledClickOpen}>
          <MicrophoneIcon style={props.iconStyle ? props.iconStyle : {}} />
        </IconButton>
      )}

      <Dialog
        open={open}
        // fullWidth
        onClose={handleClose}
        // maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"?"}</DialogTitle> */}
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon style={{ fontSize: "14" }} />
          </IconButton>
          <div className={classes.flex}>
            {/* {alert(props.initLang)} */}
            {props.initLang
              ? langList.map((item) => {
                  if (props.initLang == item.langCode) {
                    // alert("Language List");
                    return (
                      <IconButton onClick={onClickLanguage}>
                        <Typography
                          data-key={item.langCode}
                          variant="caption"
                          className={
                            selectedLangCode == item.langCode
                              ? classes.selectedLang
                              : ""
                          }
                        >
                          {item.display}
                        </Typography>
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })
              : langList.map((item, index) => {
                  return (
                    <IconButton onClick={onClickLanguage} key={index}>
                      <Typography
                        data-key={item.langCode}
                        variant="caption"
                        className={
                          selectedLangCode == item.langCode
                            ? classes.selectedLang
                            : ""
                        }
                      >
                        {item.display}
                      </Typography>
                    </IconButton>
                  );
                })}
          </div>
          <div style={{ alignContent: "center", textAlign: "center" }}>
            <IconButton onClick={onClickSpeechIcon}>
              <Avatar
                sizes="45"
                className={clsx(classes.avatar, {
                  [classes.avatarBackgroundRed]: speechStart,
                  [classes.avatarBackgroundWhite]: !speechStart,
                })}
              >
                <MicrophoneIcon
                  className={clsx(classes.microphoneIcon, {
                    [classes.iconColorWhite]: speechStart,
                    [classes.iconColorRed]: !speechStart,
                  })}
                />
              </Avatar>
            </IconButton>

            <DialogContentText id="alert-dialog-description">
              {offline
                ? "Internet Required"
                : speechStart
                ? "Listening..."
                : "Click on the mic to retry !"}

              {/* <Typography variant={"h6"} color={"primary"}>
                {text}
              </Typography> */}
            </DialogContentText>
            <div>
              <InputBase
                className={classes.input}
                placeholder="Or Type"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={handleOnTextChange}
                value={text}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Clear
          </Button> */}
          <Button onClick={onSearchClick} color="primary">
            {!props.labelText ? "Search" : props.labelText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
