import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, Link } from "@material-ui/core";
import { VideoCard } from "../Cards";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import axios from "axios";
import { BlogCard } from "../Cards";
import { grey } from "@material-ui/core/colors";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function BlogWrapper(props) {
  const [videos, setVideos] = useState();
  let history = useHistory();

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            // paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          Read
        </Typography>

        <Typography
          variant="h5"
          style={{
            // fontWeight: 600,
            paddingTop: 16,
            // paddingBottom: 16,
          }}
        >
          <Link onClick={() => history.push("./Shop")}>See All</Link>
        </Typography>
      </div>
      <Typography
        variant="h4"
        style={{
          fontWeight: 400,
          paddingTop: 8,
          paddingBottom: 16,
          flexGrow: 1,
          color: grey[500],
        }}
      >
        Latest news in your language
      </Typography>
      {/* <div
        style={{
          overflowX: "scroll",
          marginBottom: 16,
        }}
      >
        <Grid container style={{ flexWrap: "nowrap" }} spacing={2}>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
          <Merchandise></Merchandise>
        </Grid>
      </div> */}
      <Carousel
        responsive={responsive}
        // partialVisible={true}
        // customLeftArrow={<ArrowBackIcon></ArrowBackIcon>}
        // customRightArrow={<ArrowForwardIcon></ArrowForwardIcon>}
        // removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        <BlogCard merchandiseID="merchandise1.webp"></BlogCard>
        <BlogCard merchandiseID="merchandise2.jpg"></BlogCard>
        <BlogCard merchandiseID="merchandise3.webp"></BlogCard>
        <BlogCard merchandiseID="merchandise4.webp"></BlogCard>
        <BlogCard merchandiseID="merchandise5.webp"></BlogCard>
        <BlogCard merchandiseID="merchandise6.webp"></BlogCard>
      </Carousel>
    </React.Fragment>
  );
}
