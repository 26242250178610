import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Grid,
  Link,
  Container,
  Divider,
  Avatar,
  IconButton,
} from "@material-ui/core/";
import { FaceOutlined as FaceIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { grey, green } from "@material-ui/core/colors";
import { ScheduleCard } from "../Cards";
import { BottomAppBar } from "../AppBar";
import ShareButton from "../UtilsComponent/shareButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import NotifButton from "../UtilsComponent/notificationButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "transparent",
    // position: "",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
  },
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 20,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function About() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  function onImageLoad() {
    setLoading(false);
  }

  return (
    <div>
      <div className={classes.toolbar} />
      <AppBar className={classes.appBar} color={"inherit"} position="static">
        <Toolbar>
          <Typography variant="h1" className={classes.title}>
            About
          </Typography>
          <NotifButton />
        </Toolbar>
      </AppBar>

      {/* <div className={classes.toolbar} /> */}
      <Divider light></Divider>
      <Container maxWidth="xl" style={{ paddingBottom: "30vh" }}>
        <Grid
          container
          spacing={1}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12}>
            <div style={{ marginTop: 16, position: "relative" }}>
              {loading && (
                <Skeleton
                  variant="circle"
                  height={128}
                  width={128}
                  style={{ position: "absolute", zIndex: 2 }}
                />
              )}

              <img
                src="https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/profilePicXs.jpg"
                onLoad={onImageLoad}
                style={{
                  height: 128,
                  width: 128,
                  objectFit: "cover",
                  borderRadius: 60,
                  //   position: "absolute",
                }}
              ></img>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ paddingTop: 8 }} variant="h4">
              Guru Grace Stories
            </Typography>
            {/* </Grid>
          <Grid item xs={6}> */}
            <Typography variant="caption" style={{ color: grey[900] }}>
              <p>
                This app features conversations between Digvijay ji and AOL
                teachers /volunteers on their experiences in the path of
                spirituality, knowledge discourses and mantra chanting sessions.
              </p>
              <p>
                If you have any rare photo of Gurudev which you would like to
                share with us or you want to share your experience, feel free to
                share with us.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <Typography style={{ paddingTop: 8 }} variant="h4">
              Share your Guru Stories
            </Typography>
            <Typography variant="caption" style={{ color: grey[900] }}>
              If you have any rare photo of Gurudev which you would like to
              share with us or you want to share your experience of Grace Story
              with Gurudev Sri Sri Ravi Shankar ji or on spiritual path, feel
              free to share with us.
            </Typography> */}
            {/* <br /> */}
            <center>
              <Button
                onClick={() =>
                  window.open(
                    "https://wa.me/918600033323?text=Jai Gurudev Digvijay ji"
                  )
                }
                variant="contained"
                color="primary"
                style={{ background: green[400] }}
                startIcon={<WhatsAppIcon />}
              >
                Share Story/Pics
              </Button>
            </center>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography variant="h4" style={{ color: grey[900] }}>
              Liked the App? Share and support us
            </Typography>
          </Grid>

          {/* <Button
            variant="contained"
            // color="primary"
            size="small"
            // className={classes.button}
            startIcon={<ShareButton />}
          >
            Share
          </Button> */}
          <Grid item xs={12}>
            <ShareButton button></ShareButton>
          </Grid>
        </Grid>
        <MadeWith />
      </Container>
      <BottomAppBar selectedMenu="About"></BottomAppBar>
    </div>
  );
}

function MadeWith() {
  return (
    <>
      <div style={{ margin: 16, width: "100%" }}>
        <center>
          <Typography
            component="div"
            variant="caption"
            style={{ fontWeight: 600 }}
          >
            Made with ❤ by <a href="https://aapkaapp.com"> AapkaApp.com</a>
          </Typography>

          {/* <a href="https://aapkaapp.com"> */}
          <Typography variant="caption">A Sumeru Product</Typography>
          {/* </a> */}
        </center>
      </div>
    </>
  );
}
