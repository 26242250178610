import React, { useState } from "react";
import { fade } from "@material-ui/core/styles";
import {
  Typography,
  Fab,
  Button,
  IconButton,
  Grid,
  Paper,
  Container,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { PlayArrow, Pause, ArrowBack } from "@material-ui/icons";
import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import { makeStyles } from "@material-ui/styles";
import { convertMS } from "../../../../Static/utilsFunc";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,

  marqueeContent: {
    animation: "$scrolling 10s linear infinite",
  },

  "@keyframes scrolling": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-50px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
}));

// const timeToAdd = 1200;
const timeToAdd = 0;
// const timeToAdd = 5;

export default function MeditationPage() {
  const classes = useStyles();
  const videoRef = React.createRef();
  const [isPlaying, setIsPlaying] = useState();

  const [mediaDuration, setMediaDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [triggerTime, setTriggerTime] = useState(false);
  const history = useHistory();

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // 1100
    if (currentTime) {
      if (mediaDuration - currentTime <= timeToAdd) {
        setTriggerTime(true);
      } else {
        setTriggerTime(false);
      }
      setCurrentTime(currentTime);
    }
    // console.log(currentTime);
  }

  function onLoadedDataGetDuration(e) {
    const currentDuration = e.target.duration;
    console.log(currentDuration);
    // const currentDurationWithOffset =
    //   currentDuration + this.state.timecodeOffset;
    // const durationInSeconds = secondsToTimecode(currentDuration);
    // setMediaDuration(parseInt(currentDuration + 1200));
    setMediaDuration(parseInt(currentDuration + timeToAdd));
    setVideoLoaded(true);
  }

  function pauseMedia() {
    // setIsPlaying(false);
    videoRef.current.pause();
    setIsPlaying(false);
  }

  function playMedia() {
    // setIsPlaying(true);
    videoRef.current.play();
    setIsPlaying(true);
  }

  function togglePlayPause() {
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  }

  function handleClickOpen() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "pranayama",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <>
      {/* <div style={{ height: "100vh", width: "100vw", position: "relative" }}> */}
      <div style={{ zIndex: 10 }}>
        <div
          style={{
            paddingTop: 8,
            color: "white",
          }}
          onClick={() => {
            history.push("/Home");
          }}
        >
          <IconButton>
            <ArrowBack style={{ color: "white" }} />
          </IconButton>
        </div>

        <div
          style={{
            width: "100vw",
            textAlign: "center",
            paddingTop: 15,
            color: "white",
          }}
        >
          <Typography variant="h2" style={{ fontWeight: "600" }}>
            Choose a Sadhana
          </Typography>
        </div>
        <Container maxWidth="xl" style={{ marginTop: 40 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper
                style={{ background: fade("#f0f0f0", 0.2) }}
                className={classes.marqueeContent}
                onClick={() => {
                  console.log("hello");
                  history.push("/meditationSadhanaKriya");
                }}
              >
                <img
                  src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBodyexclusiveGuruji.svg"
                  style={{
                    height: "300px",
                    width: "100%",
                    //   left: "20vw",
                    // objectFit: "cover",
                    zIndex: 4,
                    // position: "absolute",
                    // bottom: 0,
                    alignContent: "center",
                    opacity: "90%",

                    // padding: 16,
                  }}
                />
                <center>
                  <Typography style={{ color: "white", fontWeight: 600 }}>
                    Sudarshan Kriya
                  </Typography>
                </center>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                style={{ background: fade("#f0f0f0", 0.2) }}
                className={classes.marqueeContent}
                onClick={() => {
                  console.log("hello");
                  history.push("/meditationSadhanaChakraKriya");
                }}
              >
                <img
                  src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbAsset%201pranayamBoy.svg"
                  // className={classes.marqueeContent}
                  style={{
                    height: "300px",
                    width: "100%",
                    //   left: "20vw",
                    // objectFit: "cover",
                    zIndex: 4,
                    // position: "absolute",
                    // bottom: 0,
                    alignContent: "center",
                    opacity: "90%",

                    // padding: 16,
                  }}
                />
                <center>
                  <Typography style={{ color: "white", fontWeight: 600 }}>
                    Sudarshan Chakra Kriya
                  </Typography>
                </center>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <img
        src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBack1exclusiveGuruji.png"
        style={{
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          position: "fixed",
          top: 0,
          zIndex: -1,
        }}
      />
      <img
        src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBack2exclusiveGuruji.png"
        style={{
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          zIndex: -1,
          // zIndex: 2,
          position: "fixed",
          top: 0,
        }}
      />
      {/* <img
        src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBodyBackexclusiveGuruji.svg"
        style={{
          height: "50vh",
          width: "100vw",
          // left: "4vw",
          // left: "20vw",
          // objectFit: "cover",
          zIndex: 2,
          position: "fixed",
          bottom: 0,
          textAlign: "center",
          opacity: "50%",

          // padding: 16,
        }}
      /> */}
      {/* <div style={{ width: "100vw", textAlign: "center" }}> */}
      {/* <img
        src="https://storage.googleapis.com/app-generator-admin-featured/yoga/potraitrgbyogaBodyexclusiveGuruji.svg"
        className={classes.marqueeContent}
        style={{
          height: "30vh",
          width: "100vw",

          zIndex: 4,
          position: "fixed",
          bottom: 0,
          alignContent: "center",
          opacity: "90%",
        }}
      /> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
