import React, { useEffect, useContext } from "react";
import messaging from "./messaging";
import axios from "axios";
import { URL_REG_NOTIFICATION_TOKEN } from "../../Static/networkConstant";
import AppName from "../ReactContext/AppNameContext";

export default function FirebaseMessageInit() {
  const appConfig = useContext(AppName);

  useEffect(() => {
    messaging &&
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            console.log("currentToken", currentToken);
            sendTokenToServer(currentToken);
            //   sendTokenToServer(currentToken);
            //   updateUIForPushEnabled(currentToken);
          } else {
            // Show permission request.
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
            // Show permission UI.
            //   updateUIForPushPermissionRequired();
            requestPermission();
            //   setTokenSentToServer(false);
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // showToken("Error retrieving Instance ID token. ", err);
          // setTokenSentToServer(false);
        });

    messaging &&
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((refreshedToken) => {
            console.log("Token refreshed.");
            sendTokenToServer(refreshedToken);
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            //   setTokenSentToServer(false);
            // Send Instance ID token to app server.
            //   sendTokenToServer(refreshedToken);
            // ...
          })
          .catch((err) => {
            console.log("Unable to retrieve refreshed token ", err);
            //   showToken("Unable to retrieve refreshed token ", err);
          });
      });

    messaging &&
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        // ...
      });
  }, []);

  function requestPermission() {
    console.log("Requesting permission...");
    // [START request_permission]
    window.Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        // [START_EXCLUDE]
        // In many cases once an app has been granted notification permission,
        // it should update its UI reflecting this.
        //   resetUI();
        // [END_EXCLUDE]
      } else {
        console.log("Unable to get permission to notify.");
      }
    });
    // [END request_permission]
  }

  function sendTokenToServer(regToken) {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      influencer_id: appConfig.influencer_id,
      registration_token: regToken,
      user_id: "NA",
    };

    axios
      .post(URL_REG_NOTIFICATION_TOKEN, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return null;
}
