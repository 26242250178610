import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Grid, Link, Container } from "@material-ui/core/";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { VideoCard } from "../Cards";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogDialogueFullScreen(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { linkText, urlLink, idFromFetch, primaryText, secondaryText } = props;
  const [videos, setVideos] = useState();

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      playlistId: idFromFetch,
      from: 0,
      size: 50,
    };

    axios
      .post(urlLink, data, {
        headers: headers,
      })
      .then((response) => {
        setVideos(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link variant="outlined" color="primary" onClick={handleClickOpen}>
        {linkText}
        {/* See All */}
      </Link>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color={"inherit"} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              {/* <CloseIcon /> */}
              <ArrowBackSharp />
            </IconButton>
            {/* <Typography variant="h6" className={classes.title}>
              Sound
            </Typography> */}
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            style={{
              fontWeight: 600,
              paddingTop: 16,
              // paddingBottom: 16,
              flexGrow: 1,
            }}
          >
            {primaryText}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontWeight: 400,
              paddingTop: 8,
              paddingBottom: 16,
              flexGrow: 1,
              color: grey[500],
            }}
          >
            Featured so that you can pin it
          </Typography>

          {/* <Grid container spacing={2}>
            {videos &&
              videos.map((video) => {
                return (
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <VideoCard data={video} titleDown description></VideoCard>
                  </Grid>
                );
              })}
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                {/* <Skeleton variant="rect" width={150} height={145} /> */}
                <img
                  src={
                    "https://storage.googleapis.com/deeptrans-test-static-assets/AppGeneratorStaticFiles/" +
                    "blog1.jpg"
                  }
                  style={{
                    objectFit: "cover",
                    height: 200,
                    // width: 150,
                    // height: "100%",
                    width: "100%",
                    borderRadius: 10,
                  }}
                ></img>
              </div>
            </Grid>
            <Grid>
              मुंबई - शिवसेना नेते आणि माजी मुख्यमंत्री मनोहर जोशी यांच्या पत्नी
              अनघा मनोहर जोशी यांचे निधन झाले. आज पहाटे त्यांनी अखेरचा श्वास
              घेतल्याचं समजते. अल्पशा आजाराने त्यांचे निधन झाले आहे. अनघा यांचा
              14 मे 1964 साली मनोहर जोशी यांच्याशी विवाह झाला होता. त्यांना, एक
              मुलगा आणि दोन मुली आहेत.
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}

BlogDialogueFullScreen.propTypes = {
  linkText: PropTypes.string.isRequired,
  idFromFetch: PropTypes.string.isRequired,
  urlLink: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};
