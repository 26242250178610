import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import {
  Grid,
  Link,
  Container,
  Typography,
  AppBar,
  IconButton,
  Toolbar,
} from "@material-ui/core/";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    background: "#fafafa",
  },
  toolbar: theme.mixins.toolbar,
}));

export default function HeaderWithBack(props) {
  const classes = useStyles();
  const { primaryText, secondaryText, handleClose } = props;
  return (
    <React.Fragment>
      <AppBar className={classes.appBar} color={"inherit"} position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <ArrowBackSharp />
          </IconButton>
          <Typography
            variant="h3"
            style={{
              fontWeight: 600,
              // paddingTop: 16,
              // paddingBottom: 16,
              flexGrow: 1,
            }}
          >
            {primaryText}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <Container maxWidth="xl">
        {/* <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            // paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          {primaryText}
        </Typography> */}
        <Typography
          variant="h4"
          style={{
            fontWeight: 400,
            paddingTop: 8,
            paddingBottom: 16,
            flexGrow: 1,
            color: grey[500],
          }}
        >
          {secondaryText}
        </Typography>
      </Container>
    </React.Fragment>
  );
}
