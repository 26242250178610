import React, { useState } from "react";
import { Slider, IconButton, Typography } from "@material-ui/core";
import {
  PlayArrowOutlined,
  PauseOutlined,
  ArrowBackSharp,
} from "@material-ui/icons";
import UniversalVideoPlayer from "../../UniversalVideoPlayer/universalVideoPlayer";
import { useLocation, useHistory } from "react-router-dom";
import { iOS } from "../../../../Static/utilsFunc";
import queryString from "query-string";

let lastTimeout = "";
export default function FullScreenYoutubePlayer() {
  const videoRef = React.useRef();
  const [mediaDuration, setMediaDuration] = useState(0);
  const [mediaCurrentTime, setMediaCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const history = useHistory();
  let location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { video_id } = queryParams;
  const [showBlackOverlayIos, setShowBlackOverlayIos] = useState(true);

  function onLoadedDataGetDuration(e) {
    const currentDuration = e.target.duration;
    console.log(currentDuration);
    // const currentDurationWithOffset =
    //   currentDuration + this.state.timecodeOffset;
    // const durationInSeconds = secondsToTimecode(currentDuration);
    setMediaDuration(currentDuration);
    // setVideoLoaded(true);
  }

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // console.log(parseInt(currentTime).toString());
    setMediaCurrentTime(currentTime);
  }

  function onReady() {
    videoRef.current.play();
  }

  function handleProgressBarClickSlider(event, value) {
    console.log(value);

    const time = value;

    setCurrentTime(time);
    displayControls();
  }

  function setCurrentTime(newCurrentTime) {
    if (newCurrentTime !== "" && newCurrentTime !== null) {
      if (videoRef.current !== null) {
        // videoRef.currentTime = newCurrentTime;
        videoRef.current.seekTo(newCurrentTime);
        playMedia();
      } else {
        console.log("no videoREf found");
      }
    }
  }
  function pauseMedia() {
    // setIsPlaying(false);
    videoRef.current.pause();
    setIsPlaying(false);
  }

  function playMedia() {
    // setIsPlaying(true);
    videoRef.current.play();
    setIsPlaying(true);
  }

  function handleOnClick(e) {
    // e.preventDefault();
    // e.stopPropagation();
  }

  function overlayClick(e) {
    displayControls();
  }
  function togglePlayPause() {
    displayControls();
    setShowBlackOverlayIos(false);
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  }

  function displayControls() {
    setShowControls(true);
    lastTimeout && clearTimeout(lastTimeout);
    lastTimeout = setTimeout(function () {
      setShowControls(false);
    }, 3000);
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: "black",
        position: "relative",
      }}
    >
      {showControls && (
        <div
          style={{
            position: "absolute",
            padding: 8,
            left: 0,
            top: 0,
            zIndex: 4,
            background: "linear-gradient(black, transparent)",
            width: "100%",
          }}
        >
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackSharp style={{ color: "white" }} />
          </IconButton>
        </div>
      )}
      {showControls && (
        <div
          style={{
            position: "absolute",
            // height: "100vh",
            width: "100vw",
            zIndex: 4,
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            top: "35vh",
          }}
        >
          {isPlaying ? (
            <IconButton onClick={togglePlayPause}>
              <PauseOutlined
                style={{ height: 80, width: 80, color: "white" }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={togglePlayPause}>
              <PlayArrowOutlined
                style={{ height: 80, width: 80, color: "white" }}
              />
            </IconButton>
          )}
        </div>
      )}
      {/* Overlay for triggering controls */}
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          zIndex: 3,
          alignContent: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={overlayClick}
      ></div>

      {iOS() && showBlackOverlayIos && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            background: "black",
            zIndex: 2,
          }}
        >
          <Typography
            style={{
              color: "white",
              position: "absolute",
              left: "30vw",
              top: "40vh",
            }}
          >
            Double Tap here to Play
          </Typography>
        </div>
      )}
      <div style={{ pointerEvents: "none", height: "100vh", width: "100vw" }}>
        <UniversalVideoPlayer
          videoRef={videoRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedDataGetDuration={onLoadedDataGetDuration}
          onReady={onReady}
          // onPlay={onPlay}
          // volume={props.index == 0 ? 1 : 0}
          //   onClick={handlePlayMedia}
          // controls
          mediaUrl={"https://www.youtube-nocookie.com/watch?v=" + video_id}
          // rel=0&modestbranding=1
        ></UniversalVideoPlayer>
      </div>
      {showControls && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            padding: 16,
            right: 0,
            width: "calc(100% - 32px)",
            zIndex: 3,
            background: "linear-gradient(transparent, black)",
          }}
        >
          <Slider
            // value={value}
            // onChange={handleChange}
            color="secondary"
            aria-labelledby="continuous-slider"
            value={mediaCurrentTime}
            min={0}
            max={mediaDuration}
            // max={Number(props.data.MEDIA_DURATION_SECS)}
            onChange={handleProgressBarClickSlider}
            onClick={handleOnClick}
          />
        </div>
      )}
    </div>
  );
}
