import firebase from "./firebaseInit";

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
// const messaging=firebase.messaging();
messaging &&
  messaging.usePublicVapidKey(
    "BFD2xNK6jDa6IEgRiHmhiU64Ti4U1-9YggUGWmHaljxukKYHCszJ48dy3KhFnrzxHWnWRGV_rta2XA-l2jtle-g"
  );

export default messaging;
