export const languageVoiceConst = [
  {
    value: "English (India)",
    literalValue: "English (India)",
    langCode: "en-IN",
    display: "English",
  },
  {
    value: "Hindi (India)",
    literalValue: "हिन्दी (भारत)",
    langCode: "hi-IN",
    display: "हिन्दी",
  },
  {
    value: "Bengali (India)",
    literalValue: "বাংলা (ভারত)",
    langCode: "bn-IN",
    display: "বাংলা",
  },
  {
    value: "Gujarati (India)",
    literalValue: "ગુજરાતી (ભારત)",
    langCode: "gu-IN",
    display: "ગુજરાતી",
  },
  {
    value: "Tamil (India)",
    literalValue: "தமிழ் (இந்தியா)",
    langCode: "ta-IN",
    display: "தமிழ்",
  },
  {
    value: "Marathi (India)",
    literalValue: "मराठी (भारत)",
    langCode: "mr-IN",
    display: "मराठी",
  },
  {
    value: "Malayalam (India)",
    literalValue: "മലയാളം (ഇന്ത്യ)",
    langCode: "ml-IN",
    display: "മലയാളം",
  },
  {
    value: "Telugu (India)",
    literalValue: "తెలుగు (భారతదేశం)",
    langCode: "te-IN",
    display: "తెలుగు",
  },
  {
    value: "Kannada (India)",
    literalValue: "ಕನ್ನಡ (ಭಾರತ)",
    langCode: "kn-IN",
    display: "ಕನ್ನಡ",
  },
];
