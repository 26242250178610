import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Grid,
  Link,
  Container,
  Divider,
  Avatar,
  IconButton,
} from "@material-ui/core/";
import { FaceOutlined as FaceIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { ScheduleCard } from "../Cards";
import { BottomAppBar } from "../AppBar";
import ShareButton from "../UtilsComponent/shareButton";
import UniversalVideoPlayer from "../../UniversalVideoPlayer/universalVideoPlayer";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
  },
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 20,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ComingSoon() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar} />
      <AppBar className={classes.appBar} color={"inherit"} position="static">
        <Toolbar>
          <Typography variant="h1" className={classes.title}>
            About
          </Typography>
        </Toolbar>
      </AppBar>

      {/* <div className={classes.toolbar} /> */}

      <Container maxWidth="xl">
        <Divider light></Divider>
        <div style={{ position: "sticky", top: 0, background: "white" }}>
          <Typography
            variant="h3"
            style={{
              fontWeight: 600,
              paddingTop: 16,
              paddingBottom: 8,
              flexGrow: 1,
            }}
          >
            Coming Soon
          </Typography>
          <ShareButton></ShareButton>
        </div>
        {/* <UniversalVideoPlayer
          mediaUrl={
            "https://www.youtube-nocookie.com/embed/live_stream?channel=UC6K7yzj2kV3v3sASdaYCY0Q"
          }
        ></UniversalVideoPlayer> */}

        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/live_stream?channel=UCgteFf0CN872AdezJ4QDU7g"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Container>
      <BottomAppBar selectedMenu="About"></BottomAppBar>
    </div>
  );
}
