export const FILENAME = "File";

// const API_ENDPOINT_URL = "http://192.168.0.100:5007/";
const API_ENDPOINT_URL = "https://api.deepdiveai.in/appgenviewapis/";

// const API_ENDPOINT_URL_SEARCH = "http://192.168.0.100:4000/searchserver/v1/";
const API_ENDPOINT_URL_SEARCH =
  "https://api.deepdiveai.in/appgensearchserver/v1/";
// const API_ENDPOINT_URL_GENERAL = "http://192.168.0.105:5000/";

// const API_ENDPOINT_URL_NODE = "http://localhost:7000/admin/";
const API_ENDPOINT_URL_NODE =
  "https://appgeneratorapi-6kxvcupweq-de.a.run.app/admin/";

// URl Constants()
// const API_ENDPOINT_URL = "http://192.168.0.101:8080/api/deeptrans/v1/";
// const API_ENDPOINT_URL_SEARCH = "http://192.168.11.18:8080/api/deeptrans/v1/";

// URl Constants(Prod)
// const API_ENDPOINT_URL = "https://api.deepdiveai.in/api/deeptrans/v1/";
// const API_ENDPOINT_URL_SEARCH = "https://api.deepdiveai.in/searchserver/v1/";

export const URL_GET_PLAYLIST_VIDEOS = API_ENDPOINT_URL + "getplayListVideos";
export const URL_GET_RECENT_VIDEOS = API_ENDPOINT_URL + "getrecentvideos";
export const URL_GET_PLAYLISTS = API_ENDPOINT_URL + "getPlaylists";
export const URL_GET_SHORTS = API_ENDPOINT_URL + "getShortVideos";

export const URL_GET_SEARCH = API_ENDPOINT_URL_SEARCH + "search";

// export const URL_GET_APP_ID = API_ENDPOINT_URL_GENERAL + "getAppID";

//Node Api
export const URL_GET_RECENT_FEATURED_CARDS =
  API_ENDPOINT_URL_NODE + "getRecentFeaturedCards";

export const URL_GET_INFLUENCER_CONFIG =
  API_ENDPOINT_URL_NODE + "getInfluencerConfig";

export const URL_REG_NOTIFICATION_TOKEN =
  API_ENDPOINT_URL_NODE + "addNewRegistrationToken";
