import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { VideoCard } from "../Cards";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import {
  COLLECTION_USER,
  SUB_COLLECTION_YOUTUBE_PLAYLIST,
  SUB_COLLECTION_APP_FIRST_VIDEO,
} from "../../../../Static/appContstants";
import axios from "axios";
import { SeeAllFullScreen } from "../Dialogue/";
import firebase from "../../../Firebase/firebaseInit";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import { useHistory } from "react-router-dom";

const arr = [
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/shortStory.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/spotlight.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/seniorTeacher.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/highlights.jpg",
  "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/discussions1.jpg",
  //   "https://storage.googleapis.com/app-generator-admin-featured/playlist_images/xyncAaix2LZWSZOgpRvqNVUcv3t2/highlights.jpg",
];

const arrName = [
  "Switch",
  "Sweet Spot",
  "Meditate and sale",
  "Highlights",
  "Engaging Stackeholders",
];

export default function PlaylistCarousel(props) {
  const [playlists, setPlaylists] = useState();
  //   const [playlistData, setPlaylistData] = useState();
  const appConfig = useContext(AppName);
  const history = useHistory();

  // const

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_YOUTUBE_PLAYLIST)
      // .where("playlistId", "in", appConfig.playlist_seq.slice(0, 5))
      .where("pinned", "==", true)
      .limit(5)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        // console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayToPush.push(doc.data());
          console.log(doc.id, " => ", doc.data());
        });
        setPlaylists(arrayToPush.reverse());
      });
  }, []);

  function onImageClick() {}

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            // paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          Playlist
        </Typography>
      </div>
      {/* <Typography variant="caption">First released in app</Typography> */}

      <div
        style={{
          overflowX: "auto",
          marginBottom: 8,
          overflowY: "hidden",
          display: "inline-flex",
        }}
      >
        <Grid container spacing={1} wrap="nowrap">
          {/* <Grid container spacing={2} wrap="wrap"> */}
          {/* <Grid container item xs={12}> */}
          {playlists?.map((playlist, index) => {
            return (
              <Grid
                item
                xs={3}
                sm={3}
                md={1}
                lg={1}
                style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                key={index}
              >
                {/* <center> */}

                <div
                  onClick={() => {
                    // console.log("inside Onclick");
                    return history.push(
                      "/playlist?id=" +
                        playlist.playlistId +
                        "&primaryText=" +
                        playlist.title +
                        "&dataSource=ES" +
                        "&playlist_image=" +
                        arr[index]
                    );
                  }}
                >
                  <center>
                    <img
                      src={arr[index]}
                      style={{
                        maxWidth: "100%",
                        maxHeight: 76,
                        height: "auto",
                        width: "auto",
                        borderRadius: 100,
                        objectFit: "cover",
                        display: "block",
                        boxShadow: "-1px 3px 8px -5px rgba(122,117,122,0.8)",
                      }}
                    />

                    <Typography component="span" variant="caption">
                      {playlist.modified_title
                        ? playlist.modified_title
                        : playlist.title}

                      {/* {arrName[index]} */}
                    </Typography>
                  </center>
                </div>
                {/* </center> */}
              </Grid>
            );
          })}

          {!playlists &&
            [0, 1, 2].map((value, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                  key={index}
                >
                  <Skeleton variant="rect" height={150} width={180}></Skeleton>
                </Grid>
              );
            })}
          {/* </Grid> */}
        </Grid>
      </div>
    </React.Fragment>
  );
}
