import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Divider, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { VideoCard } from "../Cards";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import {
  COLLECTION_USER,
  SUB_COLLECTION_RECENT_VIDEOS,
} from "../../../../Static/appContstants";

import firebase from "../../../Firebase/firebaseInit";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import { useHistory } from "react-router-dom";

export default function UpcomingLiveCarousel(props) {
  const [videos, setVideos] = useState();
  const history = useHistory();
  //   const [playlistData, setPlaylistData] = useState();
  const appConfig = useContext(AppName);

  // const

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_RECENT_VIDEOS)
      .orderBy("publishTime","desc")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        const live = [];
        const upcoming = [];
        const recent = [];
        const completed = [];
        console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          const temp = doc.data();
          if (temp.type == "live") {
            live.push(temp);
          } else if (temp.type == "completed") {
            completed.push(temp);
          } else if (temp.type == "recent") {
            recent.push(temp);
          } else if (temp.type == "upcoming") {
            console.log(temp.scheduledStartTime);
            console.log(new Date().getTime() / 1000);
            if (temp.scheduledStartTime.seconds < new Date().getTime() / 1000) {
              temp.type = "live";
              live.push(temp);
            } else {
              upcoming.push(temp);
            }
          }
          //   arrayToPush.push(doc.data());
          //   console.log(doc.id, " => ", doc.data());
        });
        setVideos([...live, ...completed, ...recent, ...upcoming]);
      });
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            flexGrow: 1,
          }}
        >
          {"Live & Recents"}
        </Typography>
      </div>

      <div
        style={{
          overflowX: "auto",
          marginBottom: 8,
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={1} wrap="nowrap">
          {videos?.map((video, index) => {
            return (
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={2}
                style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                key={index}
                onClick={() =>
                  window.open("https://www.youtube.com/watch?v=" + video.id)
                  // history.push("fullscreenplayer?video_id=" + video.id)
                }
              >
                <VideoCard
                  data={{
                    _source: {
                      videoID: video.id,
                      title: video.title,
                    },
                  }}
                  titleDown
                  description
                  changeThumbnail={"default"}
                  status={video.type}
                ></VideoCard>
              </Grid>
            );
          })}

          {!videos &&
            [0, 1, 2].map((value, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                  key={index}
                >
                  <Skeleton variant="rect" height={150} width={180}></Skeleton>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </React.Fragment>
  );
}
