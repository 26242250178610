import React, { useEffect, useState, useContext } from "react";
import { IconButton, Badge } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Notifications from "@material-ui/icons/NotificationsNone";

import firebase from "../../../Firebase/firebaseInit";
import AppName from "../../../ReactContext/AppNameContext";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import {
  COLLECTION_USER,
  SUB_COLLECTION_NOTIFICATION,
} from "../../../../Static/appContstants";

const ITEM_HEIGHT = "80vh";

export default function NotificationButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notif, setNotif] = useState();
  const [count, setCount] = useState();
  const appConfig = useContext(AppName);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { color } = props;

  useEffect(() => {
    const db = firebase.firestore();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    console.log(yesterday);

    const query = db
      .collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_NOTIFICATION)
      .where("record_created_date", ">", yesterday)
      .orderBy("record_created_date", "desc")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        let count = 0;

        querySnapshot.forEach(function (doc) {
          const lastViewedDate = new Date(
            window.localStorage.getItem("lastViewed")
          );

          if (
            lastViewedDate.getTime() / 1000 <
            doc.data().record_created_date.seconds
          ) {
            count = count + 1;
          }
          arrayToPush.push(doc.data());

          console.log(doc.data().record_created_date.seconds);
        });
        console.log(arrayToPush);

        setNotif(arrayToPush);
        setCount(count);
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    window.localStorage.setItem("lastViewed", new Date());
    setCount(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onNotificationClick(link) {
    if (link) {
      //   alert(link);
      history.push(link);
      //   history.push();
    } else {
      history.push("/Home");
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge color="primary" badgeContent={count}>
          <Notifications style={{ color: color ? color : "black" }} />
        </Badge>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT,
            // width: "20ch",
            background: "#fafafa",
            width: "80vw",
            boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
          },
        }}
      >
        <center>
          <Typography
            component={"div"}
            style={{
              fontWeight: 600,
              position: "sticky",
              top: 0,
            }}
          >
            Notification
          </Typography>
        </center>
        <Divider light />
        <List>
          {notif?.map((notification, index) => (
            //   <MenuItem
            //     key={option}
            //     selected={option === "Pyxis"}
            //     onClick={handleClose}
            //   >
            //     {option}
            //   </MenuItem>

            <ListItem
              button
              alignItems="flex-start"
              key={index}
              onClick={() => {
                onNotificationClick(notification.notf_click);
              }}
            >
              <ListItemAvatar style={{ height: "80", width: "80" }}>
                <Avatar alt="YT" src={notification.notf_image} />
              </ListItemAvatar>
              <ListItemText
                primary={notification.notf_title}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="caption"
                      //   className={classes.inline}
                      color="textSecondary"
                    >
                      {notification.notf_body}
                      {/* {notification.notf_title} */}
                    </Typography>

                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </div>
  );
}
