import React, { useState, createRef } from "react";
import ReactPlayer from "react-player";
import IconButton from "@material-ui/core/IconButton";
// import withDraggable from "../../customHOC/draggableHOC";
import { thisExpression } from "@babel/types";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    // this.currentTime = this.videoRef.current.getCurrentTime();
    this.timedTextEditorRef = React.createRef();
    this.state = {
      playing: false,
      volume: props.volume ? props.volume : props.volume == 0 ? 0 : 1,
      muted: false,
    };
  }

  currentTime = 0;
  duration = 0;
  // ready=false

  setVolume = (volume) => {
    console.log("volume", volume);
    this.setState({ ...this.state, volume: volume });
  };

  play = () => {
    console.log("Play");
    if (!this.state.playing) {
      this.setState({ ...this.state, playing: true });
      //   this.state.playing = { playing: true };
    }
  };

  mute = () => {
    this.setState({ ...this.state, muted: true });
  };

  unmute = () => {
    this.setState({ ...this.state, muted: false });
  };

  pause = () => {
    console.log("Pause");
    if (this.state.playing) {
      this.setState({ ...this.state, playing: false });
      //   this.state.playing = { playing: false };
    }
  };

  updateCurrentTime = () => {
    if (this.videoRef) {
      this.currentTime = this.videoRef.current.getCurrentTime();
    }
  };

  onTimeUpdate = () => {
    this.updateCurrentTime();
    const event = { target: { currentTime: this.currentTime } };
    if (this.props.onTimeUpdate) {
      this.props.onTimeUpdate(event);
    }
  };

  onDuration = (e) => {
    const event = { target: { duration: e } };
    this.duration = e;
    if (this.props.onDuration) {
      this.props.onDuration(event);
    }
  };

  onReady = (e) => {
    const event = { target: { ready: e } };
    // this.duration = e;
    if (this.props.onReady) {
      this.props.onReady(event);
    }
  };

  seekTo = (amount) => {
    this.videoRef.current.seekTo(amount, "seconds");
  };

  onPlay = () => {
    console.log("onPlay");
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  onBuffer = () => {
    console.log("onBuffer");
  };

  render() {
    return (
      <React.Fragment>
        <ReactPlayer
          url={this.props.mediaUrl}
          // id={408412961}
          // vimeoConfig={{ controls: true }}
          // config={{
          //   vimeo: {
          //     controls: true,
          //   },
          //   // youtube: {
          //   //   playerVars: { showinfo: 1 }
          //   // },
          //   // facebook: {
          //   //   appId: '12345'
          //   // }
          // }}
          // url={"https://player.vimeo.com/video/408412961"}
          playing={this.state.playing}
          onProgress={this.onTimeUpdate}
          onDuration={this.onDuration}
          onReady={this.onReady}
          volume={this.state.volume}
          onPlay={this.onPlay}
          ref={this.videoRef}
          height={this.props.height}
          width={this.props.width}
          progressInterval={100}
          playsinline={true}
          onBuffer={this.onBuffer}
          controls={this.props.controls}
          muted={this.state.muted}
        />
      </React.Fragment>
    );
  }
}

function CallPlayer(props) {
  return (
    <React.Fragment>
      <VideoPlayer
        ref={props.videoRef}
        onTimeUpdate={props.onTimeUpdate}
        onDuration={props.onLoadedDataGetDuration}
        onReady={props.onReady ? props.onReady : ""}
        mediaUrl={props.mediaUrl}
        onPlay={props.onPlay ? props.onPlay : ""}
        height={"100%"}
        width={"100%"}
        controls={props.controls ? true : false}
        volume={props.volume}
      ></VideoPlayer>
    </React.Fragment>
  );
}

// export default withDraggable(CallPlayer);
export default CallPlayer;
