import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Paper } from "@material-ui/core";
import { Typography, Divider } from "@material-ui/core";
// import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { green, grey, red, yellow, lime } from "@material-ui/core/colors";
// import Chip from "@material-ui/core/Chip";
import ReadMore from "../Dialogue/blogDialogueFullScreen";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  status: {
    fontWeight: 200,
  },
  statusGreen: {
    color: green[800],
  },
  cardTitle: {},
  cardStatus: {},
  cardChip: {},
  cardActionButton: {},
  cardAction: {},
});

const ModPaper = withStyles({
  root: {
    backgroundColor: "white",
    padding: 12,
    // borderRadius: 20
    // width: 700,
  },
})(Paper);

const ModLinearProgress = withStyles({
  root: {
    backgroundColor: lime[300],
    height: 8,
  },
  bar: {
    backgroundColor: lime[600],
  },
})(LinearProgress);

export default function BlogCard(props) {
  const classes = useStyles();
  const { color, merchandiseID } = props;

  return (
    <ModPaper>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 16, width: "40%" }}>
          {/* <Skeleton variant="rect" width={150} height={145} /> */}
          <img
            src={
              "https://storage.googleapis.com/deeptrans-test-static-assets/AppGeneratorStaticFiles/" +
              "blog1.jpg"
            }
            style={{
              objectFit: "cover",
              height: 130,
              // width: 150,
              // height: "100%",
              width: "100%",
              borderRadius: 10,
            }}
          ></img>
        </div>
        <div style={{ width: "60%" }}>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            // gutterBottom
          >
            माजी मुख्यमंत्री मनोहर जोशींच्या पत्नी अनघा यांचे निधन
          </Typography>
          <Typography variant="caption" component={"div"} gutterBottom>
            शिवसेना नेते आणि माजी मुख्यमंत्री मनोहर जोशी यांच्या पत्नी अनघा
            मनोहर जोशी निधन झाले.
          </Typography>

          <Typography
            variant="caption"
            gutterBottom
            component="div"
            style={{
              fontWeight: 300,
              //   fontSize: 20,
              // marginBottom: 8,
              // marginTop: 8,
              //   color: green[800],
            }}
          >
            3 hrs ago
          </Typography>

          <Button variant="contained" color="default" style={{ bottom: 0 }}>
            <ReadMore
              linkText="Read More"
              primaryText="माजी मुख्यमंत्री मनोहर जोशींच्या पत्नी अनघा यांचे निधन"
            ></ReadMore>
            {/* <Typography variant={"subtitle"}>Read More</Typography> */}
          </Button>
        </div>
      </div>
    </ModPaper>
  );
}
