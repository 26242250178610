import React, { useEffect, useState } from "react";
// import firebase from "./components/Firebase/firebaseInit";
import logo from "./logo.svg";
import "./App.css";
// import SignInPage from "./components/LoginPage/signIn";
// import Base from "./components/base";
import Base from "./components/YouTubeTemplate/base";
import { DownloadApp } from "./components/YouTubeTemplate/InfluencerTemplate/Pages";

import AppName from "./components/ReactContext/AppNameContext";

import {
  URL_GET_APP_ID,
  URL_GET_INFLUENCER_CONFIG,
} from "./Static/networkConstant";

import axios from "axios";
// import Shorts from "./components/YouTubeTemplate/InfluencerTemplate/Pages/shortVideos";

function App() {
  const [open, setOpen] = useState(false);
  const [routeName, setRouteName] = useState();
  const [showDownload, setShowDownload] = useState(false);

  let host = window.location.host;

  function getNameFromHost(hostName) {
    // const appName = hostName.split(".");
    const appName = ["gurugracestories"];

    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      subdomain_name: appName[0],
    };

    axios
      .post(URL_GET_INFLUENCER_CONFIG, data, {
        headers: headers,
      })
      .then((response) => {
        setRouteName(response.data.config);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  useEffect(() => {
    if (
      document.referrer.includes("android-app://") ||
      window.sessionStorage.getItem("isTwa")
    ) {
      window.sessionStorage.setItem("isTwa", true);
      setShowDownload(false);
      // alert("native app");
    } else {
      if (iOS()) {
        //this is webkit!
        // alert("in ios device");
        setShowDownload(false);
        // setShowDownload(true);
      } else {
        // alert("other Browser");
        setShowDownload(true);
      }
    }

    // alert(window.appID);
    if (window.appID) {
      setRouteName(window.appID);
    } else {
      getNameFromHost(host);
    }
  }, []);

  return (
    <React.Fragment>
      {/* <Shorts /> */}

      {/* <a href="https://wa.me/918600033323?text=Jai Gurudev Digvijay ji">
        Hello
      </a> */}

      {routeName && (
        <AppName.Provider value={routeName}>
          {showDownload ? <DownloadApp /> : <Base></Base>}
          {/* <Base></Base> */}
        </AppName.Provider>
      )}
    </React.Fragment>
  );
}

export default App;
