import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Divider,
  Avatar,
  IconButton,
  Typography,
} from "@material-ui/core/";
import { MerchandiseCard } from "../Cards";
import { grey } from "@material-ui/core/colors";
import {
  SUB_COLLECTION_MERCHANDISE_CATEGORY,
  SUB_COLLECTION_MERCHANDISE_SUB_CATEGORY,
  SUB_COLLECTION_MERCHANDISE_PRODUCT,
  COLLECTION_USER,
} from "../../../../Static/appContstants";
import firebase from "../../../Firebase/firebaseInit";
import AppName from "../../../ReactContext/AppNameContext";

export default function ShopCategoryContainer(props) {
  const { category_id, category_name } = props.data;
  const [merchandises, setMerchandises] = useState();
  const appConfig = useContext(AppName);

  useEffect(() => {
    const db = firebase.firestore();
    const query = db
      .collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_MERCHANDISE_CATEGORY)
      .doc(category_id)
      .collection(SUB_COLLECTION_MERCHANDISE_SUB_CATEGORY)
      .doc("default")
      .collection(SUB_COLLECTION_MERCHANDISE_PRODUCT)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        // console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayToPush.push(doc.data());
          //   console.log(doc.id, " => ", doc.data());
        });
        setMerchandises(arrayToPush);
      });
  }, []);

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0 }}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 8,
            flexGrow: 1,
          }}
        >
          {category_name}
        </Typography>
      </div>
      <Typography
        variant="h4"
        style={{
          fontWeight: 400,
          // paddingTop: 8,
          paddingBottom: 16,
          flexGrow: 1,
          color: grey[500],
        }}
      >
        Best in town
      </Typography>

      <Grid container spacing={2}>
        {merchandises?.map((merchandise, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <MerchandiseCard data={merchandise}></MerchandiseCard>
              {/* <Divider light></Divider> */}
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}
