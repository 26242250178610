import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/messaging";
import "firebase/firestore";
// import "firebase/analytics";
// import { useEffect } from "react";

// var firebaseConfig = {
//   apiKey: "AIzaSyD-UJFhJjNL_2TxbDsnIVNAHI-mHtiqpRE",
//   authDomain: "appgenerator-732c3.firebaseapp.com",
//   databaseURL: "https://appgenerator-732c3.firebaseio.com",
//   projectId: "appgenerator-732c3",
//   storageBucket: "appgenerator-732c3.appspot.com",
//   messagingSenderId: "560465292689",
//   appId: "1:560465292689:web:db1acd57f376236f023e30",
//   measurementId: "G-H8TGYXWK7F",
// };

var firebaseConfig = {
  apiKey: "AIzaSyC6__XvLWKXI8mqfUvxoa_WTUSv1zC3fTU",
  authDomain: "twatest-dce13.firebaseapp.com",
  databaseURL: "https://twatest-dce13.firebaseio.com",
  projectId: "twatest-dce13",
  storageBucket: "twatest-dce13.appspot.com",
  messagingSenderId: "916071710747",
  appId: "1:916071710747:web:c7782a385a780dbce3f8be",
  // measurementId: "G-G4TNY0GTTV",
  measurementId: "G-Q8D6250GLK",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
