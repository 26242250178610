import React from "react";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
  lighten,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Slide from "@material-ui/core/Slide";

import { teal, grey, cyan, deepPurple, indigo } from "@material-ui/core/colors";

export default function ChannelIdView() {
  return (
    <React.Fragment>
      <div style={{ position: "absolute", zIndex: 2, top: "20%", left: "10%" }}>
        <div style={{ width: "75%" }}>
          <Slide in direction="down" mountOnEnter unmountOnExit>
            <Typography variant="h1" fontWeight={500}>
              You’re just <span style={{ color: deepPurple["A200"] }}>ONE</span>{" "}
              Step away from your Personalized YouTube App
            </Typography>
          </Slide>
          <br />
          <br />
          <TextField
            id="standard-helperText"
            label="Channel ID"
            // defaultValue="Enter your App Name"
            helperText="Enter Channel-ID or channel-Url"
            style={{ width: "100%" }}
            size="large"
          />
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor: deepPurple["A200"] }}
          >
            Create
          </Button>
        </div>
      </div>
      {/* Background Elements */}
      <Slide in direction="up" mountOnEnter unmountOnExit>
        <img
          src="https://storage.googleapis.com/deeptrans-test-static-assets/Asset%208_youtube.svg"
          height="40%"
          width="40%"
          style={{ position: "fixed", right: "-3%", bottom: 20, zIndex: 1 }}
        ></img>
      </Slide>
      <div
        style={{
          position: "fixed",
          // right: 5,
          width: "100%",
          height: "100%",
          backgroundColor: grey[50],
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          right: 0,
          width: "17%",
          height: "100%",
          backgroundColor: deepPurple["A200"],
        }}
      ></div>
    </React.Fragment>
  );
}

// Template1.propTypes = {
//   Heading: PropTypes.string,
//   ActionButtonText: PropTypes.string,
//   SideColor: PropTypes.string,
//   SideImage: PropTypes.string,
//   TextInput: PropTypes.string,
// };
