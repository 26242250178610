import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";

// import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import {
  CarousalTemplate,
  StoryCarousel,
  MerchandiseWrapper,
  ScheduleCarousal,
  RecentCarousal,
  BlogCarousel,
  AppFirstCarousel,
  PlaylistCarousel,
  PopularTeacher,
  UpcomingLiveCarousel,
} from "./Carousel";
import { Search } from "./Search";
import { AppBar, BottomAppBar, TopAppBar } from "./AppBar";
import withAuthnetication from "../../session/withAuthentication";

import { URL_GET_PLAYLISTS } from "../../../Static/networkConstant";
import axios from "axios";
// import App from "../../../App";
import AppName from "../../ReactContext/AppNameContext";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import CircularProgress from "@material-ui/core/CircularProgress";

import HomeScreenButtons from "./Containers/homScreenButtonsContainer";

import firebase from "../../Firebase/firebaseInit";
import {
  SUB_COLLECTION_EXCLUSIVE_PLAYLIST,
  COLLECTION_USER,
} from "../../../Static/appContstants";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 30,
  },
}));

// export default withAuthnetication(Wrapper);

export default function Wrapper() {
  const classes = useStyles();
  const appConfig = useContext(AppName);
  const [allPlaylistInfo, setAllPlaylistInfo] = useState();
  const [playelistsInfo, setPlayelistsInfo] = useState();
  const [infiniteIndex, setInfiniteIndex] = useState(4);
  const [isLoading, setIsLoading] = useState(false);

  const [teacherPlaylists, setTeacherPlaylists] = useState();
  useBottomScrollListener(bottomCallback);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_PLAYLIST)
      .where("display_at", "==", "home")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];
        // console.log(querySnapshot);
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayToPush.push(doc.data());
          //   console.log(doc.id, " => ", doc.data());
        });
        setTeacherPlaylists(arrayToPush);
      });
  }, []);

  useEffect(() => {
    const arrayToSet = [];

    appConfig.playlist_seq.map((playlist_id, index) => {
      // if (index <= 5) {
      arrayToSet.push(playlist_id);
      // }
    });
    setAllPlaylistInfo(arrayToSet);
    // setPlayelistsInfo(arrayToSet);
    // !appConfig.playelist_seq && getPlaylistfromES();
  }, []);

  function loadMorePlaylist() {
    console.log("loadMorePlaylist");
    if (infiniteIndex < allPlaylistInfo.length && !isLoading) {
      setPlayelistsInfo(allPlaylistInfo.slice(0, infiniteIndex));
      setIsLoading(true);
      console.log("updatingIndex");
      setInfiniteIndex(infiniteIndex + 2);
    }
  }

  function loaded() {
    console.log("loaded");
    setIsLoading(false);
  }

  function bottomCallback() {
    console.log("at bottom");
    loadMorePlaylist();

    // var queue = [];
    // queue.push(2); // queue is now [2]
    // queue.push(5); // queue is now [2, 5]
    // var i = queue.shift();
  }

  function getPlaylistfromES() {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      deepdiveAppId: appConfig.influencer_id,
    };

    axios
      .post(URL_GET_PLAYLISTS, data, {
        headers: headers,
      })
      .then((response) => {
        setPlayelistsInfo(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      {/* <div className={classes.toolbar} /> */}
      <div
        style={{
          scrollSnapType: "both proximity",
          scrollPadding: "50px 0 0 50px",
        }}
      >
        <Container maxWidth="xl" style={{ paddingBottom: 50 }}>
          <StoryCarousel></StoryCarousel>

          <HomeScreenButtons />

          <UpcomingLiveCarousel />

          {appConfig.blog && (
            <React.Fragment>
              <BlogCarousel></BlogCarousel>
              <Divider light></Divider>
            </React.Fragment>
          )}

          {/* {appConfig.app_first && <AppFirstCarousel></AppFirstCarousel>} */}

          <PlaylistCarousel></PlaylistCarousel>

          {teacherPlaylists?.map((teacherPlaylist, index) => {
            return (
              <PopularTeacher
                key={index}
                data={teacherPlaylist}
                // data={{ title: "International Stories", type: "GL" }}
              />
            );
          })}
          <Search></Search>
          {/* <PopularTeacher data={{ title: "Indian Stories", type: "IN" }} />
          <PopularTeacher
            data={{ title: "International Stories", type: "GL" }}
          /> */}

          {/* {appConfig.merchandise && (
          <React.Fragment>
            <MerchandiseWrapper></MerchandiseWrapper>
          </React.Fragment>
        )} */}

          {/* <RecentCarousal></RecentCarousal> */}
          {/* <Divider light></Divider> */}
          {appConfig.consultance && (
            <React.Fragment>
              <ScheduleCarousal></ScheduleCarousal>
              <Divider light></Divider>
            </React.Fragment>
          )}

          {playelistsInfo &&
            playelistsInfo.map((playelistInfo, index) => {
              return (
                // index < 2 && (

                <div key={index}>
                  <CarousalTemplate
                    playelistInfo={playelistInfo}
                    loaded={loaded}
                  ></CarousalTemplate>
                  {/* <Divider light></Divider> */}
                </div>
              );
            })}
          {isLoading && <CircularProgress color="secondary" />}
        </Container>
      </div>
      <BottomAppBar selectedMenu="Home"></BottomAppBar>
    </React.Fragment>
  );
}
