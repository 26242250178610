import React, { useEffect } from "react";
import { ViewTemplateController } from "./VIewTemplate";
import { InfluencerTemplate } from "./InfluencerTemplate";
import { SearchResults } from "./InfluencerTemplate/Search";
import {
  ShopPage,
  AppointmentPage,
  ComingSoon,
  AboutPage,
  ShortVideos,
  // GridPlayView,
  DownloadApp,
  // VideoPlaylistView,
  CardStylePlay,
  FullScreenPLayer,
  MeditationPage,
  IntroPage,
  Checkout,
  DonationPage
} from "./InfluencerTemplate/Pages";

import {
  SadhnaChakraKriya,
  SadhnaKriya,
} from "./InfluencerTemplate/Pages/dailySadhana";
import SignIn from "../YouTubeTemplate/InfluencerTemplate/SignIn/signin";
import PrivacyPolicy from "./InfluencerTemplate/Pages/privacyPolicy";
import ExclusivePage from "./InfluencerTemplate/Pages/exclusivePage";
import TOS from "./InfluencerTemplate/Pages/tos";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

export default function Routes() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //Route change listener
  useEffect(() => {
    // console.log("dx change");
    console.log("Location changed");
    const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    if (window.sessionStorage.getItem("update")) {
      handleClick();
    }

    if (!isLocalhost) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration(swUrl).then((reg) => {
          if (!reg) {
            return null;
          }
          reg.update();
        });
      }
    }
  }, [location]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="An update is available, Please restart the app to get latest update"
        action={
          <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button> */}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Switch>
        {/* <PrivateRoute exact path="/Home">
      <InfluencerTemplate></InfluencerTemplate>
    </PrivateRoute> */}
        <Route exact path="/Home">
          <InfluencerTemplate></InfluencerTemplate>
        </Route>
        <Route exact path="/getStarted">
          <ViewTemplateController></ViewTemplateController>
        </Route>
        <Route path="/search">
          <SearchResults></SearchResults>
        </Route>
        <Route path="/Exclusive">
          <ExclusivePage></ExclusivePage>
        </Route>
        <Route path="/Shop">
          <ShopPage></ShopPage>
        </Route>
        <Route path="/Schedule">
          <AppointmentPage></AppointmentPage>
        </Route>
        <Route path="/signin">
          <SignIn></SignIn>
        </Route>
        <Route path="/About">
          <AboutPage></AboutPage>
        </Route>
        <Route path="/privacyPolicy">
          <PrivacyPolicy></PrivacyPolicy>
        </Route>
        <Route path="/tos">
          <TOS></TOS>
        </Route>
        <Route path="/shorts">
          <ShortVideos></ShortVideos>
        </Route>
        <Route path="/fullscreenplayer">
          <FullScreenPLayer></FullScreenPLayer>
        </Route>
        <Route path="/meditation">
          <MeditationPage />
        </Route>
        <Route path="/meditationSadhanaKriya">
          <SadhnaKriya />
        </Route>
        <Route path="/meditationSadhanaChakraKriya">
          <SadhnaChakraKriya />
        </Route>
        <Route path="/intro">
          <IntroPage />
        </Route>
        <Route path="/playlist">
          {/* <VideoPlaylistView /> */}
          <CardStylePlay />
          {/* <GridPlayView></GridPlayView> */}
        </Route>
        <Route path="/comingSoon">
          <ComingSoon />
        </Route>

        <Route path="/download">
          <DownloadApp></DownloadApp>
        </Route>
        {/* <Route path="/temp"></Route> */}
        <Route path="/checkout">
          <Checkout />
        </Route>
        <Route path="/donation">
          <DonationPage />
        </Route>
        <Route path="/">
          <IntroPage />
          {/* <ShortVideos></ShortVideos> */}
          {/* <InfluencerTemplate></InfluencerTemplate> */}
        </Route>
      </Switch>
    </>
  );
}
