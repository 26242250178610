import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Typography,
  InputBase,
  Divider,
  IconButton,
  Chip,
  Box,
  Grid,
} from "@material-ui/core";
import Carousel from "react-multi-carousel";
import { grey } from "@material-ui/core/colors";
import "react-multi-carousel/lib/styles.css";

import {
  Search as SearchIcon,
  ArrowBackIos as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIcon,
} from "@material-ui/icons";

import { useHistory } from "react-router-dom";
import firebase from "../../../Firebase/firebaseInit";
import {
  COLLECTION_USER,
  SUB_COLLECTION_PINNED_SEARCH,
} from "../../../../Static/appContstants";
import AppName from "../../../ReactContext/AppNameContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconSize: {
    // padding: 10,
    fontSize: "3em",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  inputFontSize: {
    fontSize: "4em",
    fontWeight: 800,
    width: "100%",
  },
  chipStyle: {
    background: "black",
    color: "white",
    // marginRight: 16,
    marginBottom: 8,
  },
}));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

//Why do i charge money
//Getting lost in Love
//Feeling like Suicide
//Friendzoned What to do

export default function Search() {
  const classes = useStyles();

  const [pinnedSearches, setPinnedSearches] = useState();
  const appConfig = useContext(AppName);

  let history = useHistory();

  useEffect(() => {
    const db = firebase.firestore();

    // console.log(userObj.uid);

    const query = db
      .collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_PINNED_SEARCH)
      .limit(10);

    const unsubscribe = query.onSnapshot(function (querySnapshot) {
      var cities = [];
      querySnapshot.forEach(function (doc) {
        cities.push(doc.data());
      });
      console.log(cities);
      cities.length == 0
        ? setPinnedSearches(undefined)
        : setPinnedSearches(cities);
    });

    return () => {
      console.log("unmounting socket");
      unsubscribe();
    };
  }, []);

  function onClickSearch(text, selectedLangCode) {
    // history.push("/temp");
    history.push("/search?searchString=" + text);
  }
  return (
    <React.Fragment>
      <Typography
        variant="h3"
        style={{
          fontWeight: 600,
          paddingTop: 16,
          // paddingBottom: 16,
          flexGrow: 1,
        }}
      >
        Suggested Searches
      </Typography>

      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#fafafa",
          // background: "white",
          borderBottom: "0.8 black",
        }}
      >
        {/* <div
          style={{
            marginTop: 10,
            display: "flex",
          }}
        >
          <InputBase
            className={classes.inputFontSize}
            placeholder="Search..."
            inputProps={{ "aria-label": "naked" }}
            autoFocus
          />
          <IconButton
            // color="primary"
            className={classes.iconButton}
            aria-label="directions"
          >
            <SearchIcon className={classes.iconSize} />
          </IconButton>
        </div> */}
        {/* <br /> */}
        <div
          style={{
            overflowX: "auto",
            // marginBottom: 8,
            marginBottom: 0,
            overflowY: "hidden",
            paddingTop: 16,
            // display: "inline-flex",
          }}
        >
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            {pinnedSearches?.map((pinnedSearch) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <Chip
                    className={classes.chipStyle}
                    label={
                      <Typography variant="caption">
                        {pinnedSearch.search_string}
                        {/* Why do i charge money */}
                      </Typography>
                    }
                    onClick={() => onClickSearch(pinnedSearch.search_string)}
                  />
                </Grid>
              );
            })}

            {!pinnedSearches && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <Chip
                    className={classes.chipStyle}
                    label={
                      <Typography variant="caption">
                        Why do i charge money
                      </Typography>
                    }
                    onClick={() => onClickSearch("Why do i charge money")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <Chip
                    className={classes.chipStyle}
                    label={
                      <Typography variant="caption">
                        Getting lost in Love
                      </Typography>
                    }
                    onClick={() => onClickSearch("Getting lost in Love")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <Chip
                    className={classes.chipStyle}
                    label={
                      <Typography variant="caption">Feeling like</Typography>
                    }
                    onClick={() => onClickSearch("Feeling like")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
                >
                  <Chip
                    className={classes.chipStyle}
                    label={
                      <Typography variant="caption">Friend se pyar</Typography>
                    }
                    onClick={() => onClickSearch("Friend se pyar")}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>

        {/* <div style={{ marginTop: 10 }}>
          <Carousel
            responsive={responsive}
            // partialVisible={true}
            // customLeftArrow={<ArrowBackIcon></ArrowBackIcon>}
            // customRightArrow={<ArrowForwardIcon></ArrowForwardIcon>}
            // removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <Chip
              className={classes.chipStyle}
              label={<Typography variant="caption">Who is siva</Typography>}
            />
            <Chip
              className={classes.chipStyle}
              label={
                <Typography variant="caption">Who is padmanabha</Typography>
              }
            />
            <Chip
              className={classes.chipStyle}
              label={
                <Typography variant="caption">
                  What was Albert Einstein
                </Typography>
              }
            />
            <Chip
              className={classes.chipStyle}
              label={
                <Typography variant="caption">
                  What was Albert Einstein
                </Typography>
              }
            />
            <Chip
              className={classes.chipStyle}
              label={
                <Typography variant="caption">
                  What was Albert Einstein
                </Typography>
              }
            />
          </Carousel> 
        </div>*/}
      </div>
    </React.Fragment>
  );
}
