import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Container,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import { ArrowBackSharp, PlayCircleOutline } from "@material-ui/icons";
import axios from "axios";
import VideoCard from "../Cards/videoCard";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { URL_GET_PLAYLIST_VIDEOS } from "../../../../Static/networkConstant";
import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import { grey, green, red } from "@material-ui/core/colors";
import TheatersIcon from "@material-ui/icons/Theaters";
import ShareIcon from "@material-ui/icons/Share";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import {
  COLLECTION_USER,
  SUB_COLLECTION_EXCLUSIVE_PLAYLIST,
  SUB_COLLECTION_EXCLUSIVE_SUB_PLAYLIST,
  SUB_COLLECTION_EXCLUSIVE_VIDEOS,
} from "../../../../Static/appContstants";

import firebase from "../../../Firebase/firebaseInit";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppName from "../../../ReactContext/AppNameContext";
import Button from "@material-ui/core/Button";
import { BottomAppBar } from "../AppBar";
import withAuthnetication from "../../../session/withAuthentication";
import NotifButton from "../UtilsComponent/notificationButton";

// export default withAuthnetication(GridPlayView);

export default function GridPlayView() {
  const [videos, setVideos] = useState();
  let location = useLocation();
  const history = useHistory();
  const appConfig = useContext(AppName);
  const [primaryVideo, setPrimaryVideo] = useState();
  const [watchFull, setWatchFull] = useState(false);

  const videoRef = React.createRef();
  // console.log(location);

  const queryParams = queryString.parse(location.search);
  const {
    dataSource,
    id,
    primaryText,
    subplaylist_id,
    video_id,
    playlist_image,
    premium,
  } = queryParams;

  //   const { dataSource } = props;

  useEffect(() => {
    if (dataSource == "ES") {
      getVideoFromES();
    } else {
      getVideoFromFirebase();
    }
  }, []);

  function getVideoFromFirebase() {
    const db = firebase.firestore();
    db.collection(COLLECTION_USER)
      .doc(appConfig.influencer_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_PLAYLIST)
      .doc(id)
      .collection(SUB_COLLECTION_EXCLUSIVE_SUB_PLAYLIST)
      .doc(subplaylist_id)
      .collection(SUB_COLLECTION_EXCLUSIVE_VIDEOS)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arrayToPush = [];

        querySnapshot.forEach(function (doc) {
          // arrayToPush.push(doc.data());
          const data = doc.data();
          data["videoID"] = data.youtube_id;
          data["title"] = data.video_title;

          // arrayToPush({ videoID: data.youtube_id });
          arrayToPush.push({ _source: data });
          // console.log(doc.id, " => ", doc.data());
        });
        console.log(arrayToPush);
        if (!video_id) {
          setPrimaryVideo(arrayToPush[0]._source.videoID);
        } else {
          setPrimaryVideo(video_id);
        }

        setVideos(arrayToPush);
      });

    console.log("getVideoFromFirebase");
  }

  function getVideoFromES() {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      playlistId: id,
      from: 0,
      size: 100,
    };

    axios
      .post(URL_GET_PLAYLIST_VIDEOS, data, {
        headers: headers,
      })
      .then((response) => {
        if (!video_id) {
          setPrimaryVideo(response.data[0]._source.videoID);
        } else {
          setPrimaryVideo(video_id);
        }

        setVideos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // console.log(parseInt(currentTime).toString());
    // setMediaCurrentTime(currentTime);
    if (currentTime >= 60) {
      videoRef.current.pause();
      setWatchFull(true);
    }
  }

  function onLoadedDataGetDuration() {
    videoRef.current.play();
  }

  function openYoutube() {
    // window.location = "https://www.youtube.com/watch?v=" + primaryVideo;
    // history.push("https://www.youtube.com/watch?v=" + primaryVideo);
    window.open("https://www.youtube.com/watch?v=" + primaryVideo);
  }

  function onVideoClick(e, video_id) {
    e.preventDefault();
    e.stopPropagation();
    // alert(video_id);
    videoRef.current.play();
    setPrimaryVideo(video_id);
    setWatchFull(false);
  }

  function handleClickOpen() {
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <div>
      <div
        style={{
          right: 4,
          top: 4,
          position: "fixed",
          zIndex: 5,
          background: "linear-gradient(transparent, black)",
        }}
      >
        <NotifButton color="white" />
      </div>
      <Grid container>
        {/* <img
          src={playlist_image}
          style={{
            width: "100%",
            height: "40vh",
            objectFit: "cover",
            position: "fixed",
          }}
        ></img> */}
        <picture>
          <source media="(min-width:200px)" srcset={playlist_image} />
          <img
            src={
              "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/potraitrgbArtboard%204grayscaleGuruGracePhoto192.png"
            }
            alt="default"
            style={{
              width: "100%",
              height: "40vh",
              objectFit: "cover",
              position: "fixed",
            }}
          />
        </picture>

        <Container
          maxWidth="xl"
          style={{
            top: "38vh",
            position: "relative",
            paddingBottom: "30vh",
          }}
        >
          <Grid container item xs={12}>
            <Paper
              style={{
                width: "100%",
                textAlign: "center",
                padding: 8,
                marginBottom: 16,
                boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
                position: "sticky",
                top: 0,
                zIndex: 4,
              }}
            >
              <Typography variant={"h3"} style={{ fontWeight: "600" }}>
                {primaryText}
              </Typography>
            </Paper>
            {video_id && (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={2}
                key="12345"
                //   onClick={(event) =>
                //     onVideoClick(event, video._source.videoID)
                //   }
              >
                <ImgMediaCard
                  data={{ _source: { videoID: video_id } }}

                  // primary_text={primaryText}
                ></ImgMediaCard>
                <Typography variant="h4">Related</Typography>
                <br />
              </Grid>
            )}
            {videos?.map((video, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                  key={index}
                  //   onClick={(event) =>
                  //     onVideoClick(event, video._source.videoID)
                  //   }
                >
                  <ImgMediaCard
                    data={video}
                    key={index}
                    primary_text={primaryText}
                    premium={premium ? premium : false}
                  ></ImgMediaCard>
                </Grid>
              );
            })}
            <MadeWith />
          </Grid>
        </Container>
      </Grid>
      <BottomAppBar></BottomAppBar>
      {/* </Dialog> */}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
    boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
    marginBottom: 16,
  },
});

function ImgMediaCard(props) {
  const classes = useStyles();
  console.log(props.data._source);
  const { videoID, video_title, title } = props.data._source;
  const { premium } = props;
  const [videoLoad, setVideoLoad] = useState(false);
  //   const [buttonText, setButtonText] = useState("Preview");
  const videoRef = React.createRef();
  const history = useHistory();
  //   const [watchFull, setWatchFull] = useState(false);

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // console.log(parseInt(currentTime).toString());
    // setMediaCurrentTime(currentTime);
    if (currentTime >= 70) {
      //   setWatchFull(true);
      videoRef.current.pause();
      setVideoLoad(false);
    }
  }

  function onLoadedDataGetDuration() {
    videoRef.current.play();
  }

  function openYoutube() {
    window.open("https://www.youtube.com/watch?v=" + videoID);
  }

  //   function onVideoClick(e, video_id) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     // alert(video_id);
  //     videoRef.current.play();
  //     // setPrimaryVideo(video_id);
  //     setWatchFull(false);
  //   }

  function onThumbnailClick() {
    
    premium
      ? history.push("/fullscreenplayer?video_id=" + videoID)
      : history.push(
          "/shorts?youtubeVideoId=" +
            videoID +
            "&start_sec=" +
            10 +
            "&end_sec=" +
            70 +
            "&title=" +
            title +
            "&no_intro=" +
            1
        );
    // setVideoLoad(true);
  }

  function onVideoClick(e) {
    console.log("inVideoClick");
    e.preventDefault();
    e.stopPropagation();
    // videoRef.current.pause();
    // videoRef.current.play();
  }

  function handleClickOpen() {
    window.gtag("event", "share", {
      event_category: "Engagement",
      event_label: "app",
    });
    if (navigator.canShare) {
      navigator
        .share({
          //   files: filesArray,
          title: "GuruGraceStories",
          text:
            "Unlock exclusive videos and dive into spiritual journey with Gurugracestories in a more engaging way. Download Now",
          url:
            "https://play.google.com/store/apps/details?id=com.aapkaapp.gurugracestories.twa.one",
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {!videoLoad ? (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
                top: "40%",
                left: "36%",
                zIndex: 3,
              }}
              onClick={onThumbnailClick}
            >
              <Button
                variant="contained"
                startIcon={<PlayCircleOutline />}
                style={{ background: "black", color: "white" }}
              >
                {/* <Typography >Preview</Typography> */}
                {premium ? "Watch Now" : "Preview"}
              </Button>
            </div>
            {/* Overlay */}
            <div
              style={{
                //   position: "relative",
                position: "absolute",
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.44) 33%,rgba(0,0,0,0.33) 50%,rgba(0,0,0,0.28) 58%,rgba(0,0,0,0) 100%)",
                zIndex: 2,
                width: "100%",
                height: "100%",
              }}
            ></div>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="auto"
              width="100%"
              image={"https://i3.ytimg.com/vi/" + videoID + "/mq2.jpg"}
              //   image="/static/images/cards/contemplative-reptile.jpg"
              title={video_title}
              onClick={onThumbnailClick}
            />
          </div>
        ) : (
          <div onClick={onVideoClick} style={{ height: "30vh" }}>
            <UniversalVideoPlayer
              videoRef={videoRef}
              onTimeUpdate={handleTimeUpdate}
              onLoadedDataGetDuration={onLoadedDataGetDuration}
              // controls={true}
              //   onClick={handlePlayMedia}
              // mediaUrl={"https://www.youtube.com/watch?v=" + props.data.videoID}
              mediaUrl={
                "https://www.youtube-nocookie.com/watch?v=" +
                videoID +
                "&start=10&end=72&?rel=0&modestbranding=1"
              }
            ></UniversalVideoPlayer>
          </div>
        )}

        {title && (
          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              style={{ fontWeight: 600 }}
            >
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {"Conversations between Digvijay ji and " +
                props.primary_text +
                " and experiences in the path of spirituality, knowledge discourses and sessions."}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
      <CardActions>
        {!premium && (
          <Button
            size="medium"
            color="secondary"
            startIcon={<YouTubeIcon />}
            onClick={openYoutube}
            style={{ color: red[600], flexGrow: 0 }}
          >
            Watch Full
          </Button>
        )}
        <span style={{ flexGrow: 1 }}></span>
        <Button
          size="medium"
          //   variant="contained"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<WhatsAppIcon />}
          style={{ color: green[600], flexGrow: 0 }}
        >
          Share
        </Button>
      </CardActions>
    </Card>
  );
}

function MadeWith() {
  return (
    <>
      <div style={{ margin: 16, width: "100%" }}>
        <center>
          <Typography
            component="div"
            variant="caption"
            style={{ fontWeight: 600 }}
          >
            Made with ❤ by <a href="https://aapkaapp.com"> AapkaApp.com</a>
          </Typography>

          {/* <a href="https://aapkaapp.com"> */}
          <Typography variant="caption">A Sumeru Product</Typography>
          {/* </a> */}
        </center>
      </div>
    </>
  );
}
