import React, { useState, useEffect, useContext } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { Grid, Container } from "@material-ui/core/";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
// import Skeleton from "@material-ui/lab/Skeleton";

import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import SearchPlayerControls from "./searchPlayerControler";

// import ScrollTranscriptionBlock from "./scrollTranscriptBlock";
import axios from "axios";

import {
  green,
  grey,
  red,
  yellow,
  lime,
  blueGrey,
  blue,
} from "@material-ui/core/colors";

// import

// import {
//   secondsToTimecode,
//   timecodeToSeconds,
// } from "../../editor/util/timecode-converter";

const useStyles = makeStyles((theme) => ({
  paperBase: {
    boxShadow: "0 0px 0px 0 rgba(0,0,0,.05)",
    // border: "1px solid #e7e7e7",
    borderBottom: "1px solid #e7e7e7",

    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.05),
      boxShadow: "0 2px 10px 0 rgba(0,0,0,.05)",
    },
    cursor: "pointer",
    backgroundColor: "#f7f7f7",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // textAlign: "center"
  },
  paperClose: {
    // transition: theme.transitions.create("all", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen
    // }),
    height: "112px",
    overflowY: "hidden",
    backgroundColor: fade(theme.palette.common.white, 0.9),
  },
  paperOpen: {
    backgroundColor: fade(theme.palette.common.black, 0.05),
    boxShadow: "0 2px 10px 0 rgba(0,0,0,.05)",
    height: "280px",
    // transition: theme.transitions.create("all", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // })
    // height: "100px",
    // overflowY: "hidden"
  },
  flex: {
    display: "flex",
  },
  flexGrow: {
    flexGrow: 1,
  },
  transition: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fontGrey: {
    color: fade(theme.palette.common.black, 0.5),
  },
}));

const controlHeight = "300px";

export default function SearchResultsPlayer(props) {
  const classes = useStyles();

  const [videoUrl, setVideoUrl] = useState(false);

  const [mediaDuration, setMediaDuration] = useState(0);
  const [mediaCurrentTime, setMediaCurrentTime] = useState(0);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const videoRef = React.createRef();

  function onLoadedDataGetDuration(e) {
    const currentDuration = e.target.duration;
    console.log(currentDuration);
    // const currentDurationWithOffset =
    //   currentDuration + this.state.timecodeOffset;
    // const durationInSeconds = secondsToTimecode(currentDuration);
    setMediaDuration(currentDuration);
    setVideoLoaded(true);
  }

  function handleTimeUpdate(e) {
    const currentTime = e.target.currentTime;
    // console.log(parseInt(currentTime).toString());
    setMediaCurrentTime(currentTime);
  }

  //   function getVideoUrl() {
  //     const options = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const data = {
  //       deepdive_id: props.data.videoID,
  //     };
  //     axios.post(URL_GET_VIDEO_URLS, data, options).then((res) => {
  //       console.log(res.data);
  //       setVideoUrl(res.data.mediaSignedUrl);
  //     });
  //   }

  //   useEffect(() => {
  //     if (!videoUrl) {
  //       getVideoUrl();
  //     }
  //   }, []);

  const videoPlayer = (
    <UniversalVideoPlayer
      videoRef={videoRef}
      onTimeUpdate={handleTimeUpdate}
      onLoadedDataGetDuration={onLoadedDataGetDuration}
      //   onClick={handlePlayMedia}
      // mediaUrl={"https://www.youtube.com/watch?v=" + props.data.videoID}
      mediaUrl={
        "https://www.youtube-nocookie.com/watch?v=" +
        props.data.videoID +
        "&?rel=0&modestbranding=1"
      }
    ></UniversalVideoPlayer>
  );

  const videoPlayerControl = (
    <SearchPlayerControls
      videoRef={videoRef}
      mediaDuration={mediaDuration}
      videoLoaded={videoLoaded}
      currentTime={mediaCurrentTime}
      open={true}
      //   tags={props.tags}
      data={props.data}
      controlHeight={controlHeight}
    ></SearchPlayerControls>
  );

  const captionView = {};

  return (
    <Grid container alignContent="center" style={{ textAlign: "center" }}>
      <Grid item xs={12}>
        <div
          style={{
            // height: "100vh",
            // width: "100wh",
            height: "calc(100vh - " + controlHeight + ")",
            pointerEvents: "none",
          }}
        >
          {videoPlayer}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>{videoLoaded && videoPlayerControl}</div>
      </Grid>
    </Grid>
  );
}
