import React, { useState, useEffect } from "react";
// import stripePromise from "../configs/stripe";
import { Button, Typography, Container, Grid } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import { green, red } from "@material-ui/core/colors";

import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51HOzj5DRFhV6lbF0la7CfLFxNmm4r9bh8WCo1vhAEEa3msR95IHz7wMkE8UlIrtpRmmVOwCDSrbohxjwDbGip7k600U5Ezyyx9"
);

// export default stripePromise;

export default function Checkout() {
  const [message, setMessage] = useState();
  const [status, setStatus] = useState();
  const history = useHistory();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setStatus(true);
      setMessage("Payment Success! You will receive an email confirmation.");
    }
    if (query.get("cancel")) {
      setStatus(false);
      setMessage("Payment Failed! Looks like there was some problem");
    }
  }, []);

  return (
    <>
      <Container maxWidth={"xl"}>
        <center>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {status ? (
                <CheckIcon
                  style={{ height: 64, width: 64, color: green[300] }}
                />
              ) : (
                <HighlightOffIcon
                  style={{ height: 64, width: 64, color: red[300] }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {status ? (
                <Typography variant="h2" style={{ color: green[300] }}>
                  Success
                </Typography>
              ) : (
                <Typography variant="h2" style={{ color: red[300] }}>
                  Failed
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>{message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  history.push("Home");
                }}
              >
                Back To Home
              </Button>
            </Grid>
          </Grid>
        </center>
      </Container>
      {/* {!message ? (
        <Button id="checkout-button" onClick={handleClick}>
          Checkout
        </Button>
      ) : (
        <Typography>{message}</Typography>
      )} */}
    </>
  );
}
