import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Grid,
  Link,
  Container,
  Divider,
  Avatar,
  IconButton,
} from "@material-ui/core/";
import { FaceOutlined as FaceIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackSharp from "@material-ui/icons/ArrowBackSharp";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { ScheduleCard } from "../Cards";
import { BottomAppBar } from "../AppBar";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
  },
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    height: 20,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { linkText, urlLink, idFromFetch, primaryText, secondaryText } = props;
  const [videos, setVideos] = useState();

  const arr = [
    "appointment1.jpg",
    "appointment2.jpg",
    "appointment3.jpg",
    "appointment2.jpg",
    "appointment3.jpg",
  ];

  //   useEffect(() => {
  //     const headers = {
  //       "Content-Type": "application/json",
  //     };
  //     const data = {
  //       playlistId: idFromFetch,
  //       from: 0,
  //       size: 50,
  //     };

  //     axios
  //       .post(urlLink, data, {
  //         headers: headers,
  //       })
  //       .then((response) => {
  //         setVideos(response.data);
  //         // console.log(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, []);

  return (
    <div>
      <div className={classes.toolbar} />
      <AppBar className={classes.appBar} color={"inherit"} position="static">
        <Toolbar>
          <Typography variant="h1" className={classes.title}>
            Schedule
          </Typography>

          <Avatar className={classes.large}>
            <IconButton>
              <FaceIcon />
            </IconButton>
          </Avatar>
        </Toolbar>
      </AppBar>

      {/* <div className={classes.toolbar} /> */}

      <Container maxWidth="xl">
        <Divider light></Divider>
        <div style={{ position: "sticky", top: 0, background: "white" }}>
          <Typography
            variant="h3"
            style={{
              fontWeight: 600,
              paddingTop: 16,
              paddingBottom: 8,
              flexGrow: 1,
            }}
          >
            Counselling
          </Typography>
        </div>
        <Typography
          variant="h4"
          style={{
            fontWeight: 400,
            // paddingTop: 8,
            paddingBottom: 16,
            flexGrow: 1,
            color: grey[500],
          }}
        >
          Best in town
        </Typography>

        <Grid container spacing={2}>
          {arr.map((value) => {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                style={{ flex: "0 0 auto", webkitOverflowScrolling: "touch" }}
              >
                <ScheduleCard scheduleID={value}></ScheduleCard>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <BottomAppBar selectedMenu="Schedule"></BottomAppBar>
    </div>
  );
}

// FullScreenDialogSeeAll.propTypes = {
//   linkText: PropTypes.string.isRequired,
//   idFromFetch: PropTypes.string.isRequired,
//   urlLink: PropTypes.string.isRequired,
//   primaryText: PropTypes.string.isRequired,
//   secondaryText: PropTypes.string.isRequired,
// };
