import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Container, Paper, Typography } from "@material-ui/core";
import { URL_GET_SEARCH } from "../../../../Static/networkConstant";
import { SearchVideoCard } from "../Cards";
import axios from "axios";
import { HeaderWithBackButton } from "../UtilsComponent";
import FullScreenDialogueVideoPlayer from "../Dialogue/fullScreenDiaogueVideoPlayer";
import AppName from "../../../ReactContext/AppNameContext";
import BottomAppBar from "../AppBar/bottomAppBar";
import InvokeVoiceButton from "../UtilsComponent/invokeVoiceButton";
import withAuthnetication from "../../../session/withAuthentication";
import Search from "./search";

// export default withAuthnetication(SearchResults);

export default function SearchResults() {
  const appConfig = useContext(AppName);
  let location = useLocation();
  let history = useHistory();
  const parsedString = queryString.parse(location.search);

  const [searchString, setSearchString] = useState();
  const [videos, setVideos] = useState([]);
  const [totalHits, setTotalHits] = useState("searching");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setVideos([]);
    setTotalHits("Searching");
    if (location.search) {
      const parsedString = queryString.parse(location.search);
      const stringToPass = parsedString.searchString;
      setSearchString(stringToPass);
      getVideo(stringToPass);
    }
  }, [location.search]);

  function getVideo(searchString) {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      Query: searchString,
      from: 0,
      size: 20,
      deepdiveAppId: appConfig.influencer_id,
    };

    axios
      .post(URL_GET_SEARCH, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.hits);
        setVideos(response.data.hits);
        // setTotalHits(response.data.total_hits.value + "video found");
        setTotalHits("");
      })
      .catch((error) => {
        setTotalHits("no videos matched your query");
        console.log(error);
      });
  }

  function handleClose() {
    history.push("./home");
    console.log("Handle close");
  }

  function onVoiceSearch(text, selectedLangCode) {
    history.push("/search?searchString=" + text);
    setVideos();
    setTotalHits("searching");
  }

  return (
    <React.Fragment>
      {/* {alert(searchString)} */}
      {/* <HeaderWithBackButton
        // primaryText="Inside Content Search"
        // secondaryText={totalHits + " Video Discovered"}
        secondaryText={totalHits}
        handleClose={handleClose}
      ></HeaderWithBackButton> */}
      <picture>
        <source
          media="(min-width:200px)"
          srcset={
            "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/potraitrgbArtboard%204grayscaleGuruGracePhoto192.png"
          }
        />
        <img
          src={
            "https://storage.googleapis.com/app-generator-admin-featured/misc/xyncAaix2LZWSZOgpRvqNVUcv3t2/potraitrgbArtboard%204grayscaleGuruGracePhoto192.png"
          }
          alt="default"
          style={{
            width: "100%",
            height: "40vh",
            objectFit: "cover",
            position: "fixed",
          }}
        />
      </picture>

      <Container
        maxWidth="xl"
        style={{
          top: "38vh",
          position: "relative",
          zIndex: 2,
          paddingBottom: "30vh",
        }}
      >
        <Search></Search>
        <br />
        <Grid container spacing={1}>
          <Paper
            style={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
              marginBottom: 16,
              boxShadow: "-1px 6px 19px -5px rgba(122,117,122,0.8)",
              position: "sticky",
              top: 0,
              zIndex: 10,
              display: "flex",
            }}
          >
            <Typography
              variant={"h3"}
              style={{ fontWeight: "600", flexGrow: 1 }}
            >
              {/* Inside Video Search */}
              {searchString}
            </Typography>
            <div style={{ flexGrow: 0 }}>
              <InvokeVoiceButton
                onVoiceSearch={onVoiceSearch}
                // initLang={"en-IN"}
                iconStyle={{ color: "black" }}
              ></InvokeVoiceButton>
            </div>
          </Paper>
          {totalHits && (
            <Typography variant={"h3"} style={{ fontWeight: "600" }}>
              {/* Inside Video Search */}
              {totalHits}
            </Typography>
          )}

          {videos?.map((video, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <SearchVideoCard
                  data={video}
                  // titleDown
                  description
                ></SearchVideoCard>
              </Grid>
            );
          })}
        </Grid>
        <MadeWith />
      </Container>
      <BottomAppBar />
    </React.Fragment>
  );
}

function MadeWith() {
  return (
    <>
      <div style={{ margin: 16, width: "100%" }}>
        <center>
          <Typography
            component="div"
            variant="caption"
            style={{ fontWeight: 600 }}
          >
            Made with ❤ by <a href="https://aapkaapp.com"> AppkaApp.com</a>
          </Typography>

          {/* <a href="https://aapkaapp.com"> */}
          <Typography variant="caption">A Sumeru Product</Typography>
          {/* </a> */}
        </center>
      </div>
    </>
  );
}
