import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { UniversalVideoPlayer } from "../../UniversalVideoPlayer";
import Paper from "@material-ui/core/Paper";
import { Typography, Grid, Box, Tooltip, Link } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
// import { CustomSlider } from "../UtilsComponent";
import FullScreenDialogueVideoPlayer from "../Dialogue/fullScreenDiaogueVideoPlayer";
import { Skeleton } from "@material-ui/lab";
import UniversalVideoPlayer from "../../UniversalVideoPlayer/universalVideoPlayer";
import { useHistory } from "react-router-dom";

// const width = 300;
const width = 250;
// const height = 75;
const height = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  paper: {
    overflow: "hidden",

    // height: height,
    // width: width,
    //     -webkit-box-shadow: -1px 10px 9px -6px rgba(122,117,122,0.8);
    // -moz-box-shadow: -1px 10px 9px -6px rgba(122,117,122,0.8);
    boxShadow: "-1px 3px 8px -5px rgba(122,117,122,0.8)",
  },
  backGradient: {
    background: "rgb(2,0,36)",
    // background
    // background:
    //   "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,7,255,0) 49%, rgba(249,249,249,0) 87%)",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function VideoCard(props) {
  const classes = useStyles();
  // const [showVideo, setShowVideo] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoData, setVideoData] = useState(props.data._source);
  const { showVideo, playlist_id, changeThumbnail } = props;
  const [loading, setLoading] = useState(true);
  const videoRef = React.createRef();
  const history = useHistory();
  // const { data } = props;

  function onImageLoad() {
    setLoading(false);
  }

  function showVideoToggle() {
    // open
    //   ? setTimeout(() => {
    //       setOpen(false);
    //     }, 100)
    //   : setOpen(true);
  }

  function onLoadedDataGetDuration() {
    // console.log("Ready");
    videoRef.current.play();
    setLoading(false);
  }

  function onReady() {
    setLoading(false);
    videoRef.current.play();
  }

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        {loading && !showVideo && (
          <Skeleton
            variant="rect"
            height={150}
            width={180}
            style={{ zIndex: 5 }}
          ></Skeleton>
        )}
        <Paper className={classes.paper}>
          <div
            className={!loading && classes.backGradient}
            style={{ position: "relative" }}
          >
            {/* <Link href={"https://www.youtube.com/watch?v=" + videoData.videoID}> */}
            {!showVideo ? (
              <img
                // src={
                //   "https://i3.ytimg.com/vi/" +
                //   videoData.videoID +
                //   "/hqdefault.jpg"
                // }
                // onClick={() =>
                //   history.push(
                //     "/playlist?id=" +
                //       playlist_id +
                //       "&dataSource=ES" +
                //       "&video_id=" +
                //       videoData.videoID
                //   )
                // }
                src={
                  changeThumbnail
                    ? "https://i3.ytimg.com/vi/" +
                      videoData.videoID +
                      "/mq" +
                      changeThumbnail +
                      ".jpg"
                    : "https://i3.ytimg.com/vi/" +
                      videoData.videoID +
                      "/mqdefault.jpg"
                }
                // onClick={showVideoToggle}
                style={{
                  objectFit: "cover",
                  borderRadius: 8,
                  maxWidth: "100%",
                  maxHeight: { height },
                  // width: "auto",
                  width: "100%",
                  height: "auto",
                  display: "block",
                }}
                onLoad={onImageLoad}
                // height={height}
                // width={"100%"}
              ></img>
            ) : (
              <div
                // onClick={showVideoToggle}
                style={{
                  maxWidth: "100%",
                  maxHeight: { height },
                  // width: "auto",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  pointerEvents: "none",
                }}
              >
                <UniversalVideoPlayer
                  videoRef={videoRef}
                  onLoadedDataGetDuration={onLoadedDataGetDuration}
                  // onReady={onReady}
                  controls={false}
                  //   onClick={handlePlayMedia}
                  mediaUrl={
                    "https://www.youtube-nocookie.com/watch?v=" +
                    videoData.videoID +
                    "&?rel=0&modestbranding=1"
                  }
                ></UniversalVideoPlayer>
              </div>
            )}
            {/* </Link> */}

            {props.titleCover && (
              <React.Fragment>
                <div
                  className={classes.backGradient}
                  style={{
                    height: height,
                    width: "100%",
                    // zIndex: ,
                    position: "absolute",
                    top: 0,
                  }}
                ></div>
                <Typography
                  style={{
                    position: "absolute",
                    bottom: "5%",
                    left: "2%",
                    // zIndex: 2,
                    color: "white",
                  }}
                  variant="h5"
                >
                  {videoData.title}
                </Typography>
              </React.Fragment>
            )}

            {props.status && (
              <React.Fragment>
                <div
                  // className={classes.backGradient}
                  style={{
                    // height: height,
                    // width: "100%",
                    // zIndex: ,
                    borderRadius: 8,
                    position: "absolute",
                    bottom: 4,
                    left: 4,
                    zIndex: 5,

                    background: props.status == "live" ? "#d32f2f" : "black",
                  }}
                >
                  <Typography
                    style={{
                      padding: 4,

                      color: "white",
                    }}
                    component="div"
                    variant="caption"
                  >
                    {props.status.toUpperCase()}
                  </Typography>
                </div>
              </React.Fragment>
            )}

            {/* <div
              style={{
                bottom: 0,
                padding: 8,
                position: "absolute",
                zIndex: 100,
                width: "100%",
                // height: "100%",
              }}
            >
              <CustomSlider
                aria-label="ios slider"
                // defaultValue={60}
                // marks={marks}
                marks={[
                  { value: 5 },
                  { value: 10 },
                  { value: 20 },
                  { value: 50 },
                  { value: 90 },
                ]}
                valueLabelDisplay="off"
                // value={props.currentTime}
                min="0"
                // max={props.data.MEDIA_DURATION_SECS}
                max={100}
                // onChange={handleProgressBarClickSlider}
                // onClick={handleOnClick}
              />
            </div> */}
            {/* <div
              className={classes.backGradient}
              style={{
                height: height,
                width: "100%",
                // zIndex: ,
                position: "absolute",
                top: 0,
              }}
            ></div> */}

            <Paper
              style={{
                background: "black",
                position: "absolute",
                top: "5%",
                right: "5%",
                padding: "1px",
              }}
            >
              <Typography variant="caption" style={{ color: "white" }}>
                {/* 22:00 */}
              </Typography>
            </Paper>
          </div>

          {/* <UniversalVideoPlayer mediaUrl="https://www.youtube.com/watch?v=H9fjhQMsDW4"></UniversalVideoPlayer> */}
          {/* <Typography variant="h3">Obama Meets Donald</Typography>
      <Typography variant="caption">A little bit of description</Typography> */}
        </Paper>
        <div style={{ paddingTop: "8px" }}>
          {props.titleDown && (
            // <Typography component="div" variant="caption">
            //   <Box>{videoData.title}</Box>
            // </Typography>
            <Tooltip title={videoData.title}>
              <div style={{ width: "calc(100%)" }}>
                <Typography
                  component="div"
                  variant="caption"
                  style={{ fontWeight: 600 }}
                  // width: "calc(100% - 1px)"
                  noWrap
                >
                  {videoData.title}
                </Typography>
              </div>
            </Tooltip>
          )}

          {props.description && (
            <Typography variant="caption" style={{ color: grey[500] }}>
              <Box>{videoData.description}</Box>
            </Typography>
          )}
        </div>
      </div>
      {open && (
        <FullScreenDialogueVideoPlayer
          open={open}
          data={props.data._source}
        ></FullScreenDialogueVideoPlayer>
      )}
    </React.Fragment>
  );
}
